import React from "react";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import BackArrow from "./images/back-arrow.svg";
import PropTypes from "prop-types";

const FilePreviewModal = ({
    fileName,
    fileSrc,
    onClickBack = () => {}
}) => {
    const regex = /\.pdf$/i;
    const isPdf = regex.test(fileName);
    return (
        <Modal isOpen={true}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.headerWrap"}>
                    <img src={BackArrow} onClick={onClickBack} />
                    <p styleName={"styles.heading"}>{fileName}</p>
                </div>
                {isPdf ?
                    <iframe src={fileSrc} styleName={"styles.iframeStyle"}/>
                    : <div styleName={"styles.outer"}>
                        <img src={fileSrc} />
                    </div>}
            </div>
        </Modal>
    );
};

FilePreviewModal.propTypes = {
    fileName: PropTypes.string,
    onClickBack: PropTypes.func,
    fileSrc: PropTypes.string
};

export default FilePreviewModal;
