import React from "react";
import DynamicFormFields from "../dynamic-form-fields";
import PropTypes from "prop-types";
import styles from "./styles.css";
import FormikForm from "../../shared/formik-form";

const DynamicForm = ({
    onClickSubmit = () => {},
    updateFormData = () => { },
    currentFormData = {},
    errorOnSubmitCount,
    children,
    ...restProps
}) => {
    return (
        <div>
            <FormikForm
                onClickSubmit={onClickSubmit}
                initialValues={currentFormData.initialValues}
                formValidator={currentFormData.formValidator}
                validate={currentFormData.validate}
            >
                {({values, errors, touched, isSubmitting, dirty}) => (
                    <React.Fragment>
                        <DynamicFormFields
                            values={values}
                            updateFormData={updateFormData}
                            form={currentFormData}
                            errors={errors}
                            touched={touched}
                            errorOnSubmitCount={errorOnSubmitCount}
                            isSubmitting={isSubmitting}
                            dirty={dirty}
                        />
                        {children && children({currentFormData, ...restProps})}
                    </React.Fragment>
                )}
            </FormikForm>
        </div>
    );
};

DynamicForm.propTypes = {
    onClickSubmit: PropTypes.func,
    updateFormData: PropTypes.func,
    handleOnNext: PropTypes.func,
    handleOnBack: PropTypes.func,
    currentFormData: PropTypes.object,
    children: PropTypes.func,
    restProps: PropTypes.object,
    errorOnSubmitCount: PropTypes.number
};

export default DynamicForm;
