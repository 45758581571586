import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const Callout = ({
    text = "",
    positionRight = null
}) => {
    return (
        <div styleName={`styles.nudge-texts ${positionRight ? "styles.right_arrows" : "styles.left_arrows"}`}>
            {text}
        </div>
    );
};

Callout.propTypes = {
    text: PropTypes.string,
    positionRight: PropTypes.bool
};

export default Callout;
