/* eslint-disable max-params */
/* eslint-disable no-sequences */
import { CARD_SURCHARGE, PAYMENT_GATEWAY, PAYMENT_TYPE, TIME_UNIT } from "../../constants/checkout-constants";
import { NUMBER, PAYMENT_HISTORY_STATUS } from "../../constants/app-constants";
import { getObjectByValue } from "./get-keys-by-value";
import { range } from "./array-range";
import { X_COUNTRY_CODE } from "../../constants/api-constants";
import { getCarDetailsPageURL } from "./get-detail-page-url";
import { appUrl } from "../../constants/url-constants";
import { monthNames, monthNamesValues } from "../../constants/finance-constants/month-names";

export const timeUnitsBetween = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let delta = Math.abs(endDate - startDate) / NUMBER.THOUSAND;
    const isNegative = startDate > endDate ? -1 : 1;
    return [
        ["days", NUMBER.TWENTY_FOUR * NUMBER.SIXTY * NUMBER.SIXTY],
        ["hours", NUMBER.SIXTY * NUMBER.SIXTY],
        ["minutes", NUMBER.SIXTY],
        ["seconds", 1]
        // eslint-disable-next-line no-return-assign
    ].reduce((acc, [key, value]) => (acc[key] = Math.floor(delta / value) * isNegative, delta -= acc[key] * isNegative * value, acc), {});
};

export const getConfirmBookingDate = (isoDate) => {
    const date = new Date(isoDate);
    const monthValue = date.getMonth() + 1;
    const yearValue = date.getFullYear();
    const dayValue = date.getDate();
    return `${dayValue}-${monthValue}-${yearValue}`;
};

export const getTimeLeft = (createdDate) => {
    const timeDiff = timeUnitsBetween(createdDate, new Date());
    let minutesLeft = TIME_UNIT - timeDiff.minutes;
    let secondsLeft = minutesLeft === TIME_UNIT ? 0 : TIME_UNIT - timeDiff.seconds;
    if (timeDiff.days > 0 || timeDiff.hours > 0 || minutesLeft < 0 || secondsLeft < 0) {
        minutesLeft = 0;
        secondsLeft = 0;
    }
    return {
        minutesLeft,
        secondsLeft
    };
};

export const removeObjectFromArray = (key, value, array) => {
    return array.filter((item) => {
        return item[key] !== value;
    });
};

export const getTargetObjectValueFromArray = (sourceKey, sourceValue, targetKey, array) => {
    return (array.filter((item) => {
        return item[sourceKey] === sourceValue;
    })[0] || {})[targetKey];
};

export const updateObjectOfArray = (sourceKey, sourceValue, sourcePickKey, targetKey, targetValue, targetUpdateKey, sourceArray, targetArray) => {
    const updatedValue = getTargetObjectValueFromArray(sourceKey, sourceValue, sourcePickKey, sourceArray);
    const targetIndex = targetArray.findIndex((element) => {
        if (element[targetKey] === targetValue) {
            return true;
        }
        return false;
    });

    return [
        ...targetArray.slice(0, targetIndex),
        {
            ...targetArray[targetIndex],
            [targetUpdateKey]: updatedValue
        },
        ...targetArray.slice(targetIndex + 1)
    ];
};

export const getPaymentSurcharge = (listingPrice) => {
    const surcharge = CARD_SURCHARGE * listingPrice;
    const listingPriceWithSurchange = (listingPrice + surcharge);

    return {
        surcharge,
        listingPrice,
        listingPriceWithSurchange
    };
};

export const getPaymentTypeLabel = (paymentMethodType = "") => {
    const payViaType = getObjectByValue(PAYMENT_TYPE, "value", paymentMethodType) || { label: "", value: ""};
    return payViaType.label;
};

export const getPaymentTypePrefix = (paymentMethodType = "") => {
    const payViaType = getObjectByValue(PAYMENT_TYPE, "value", paymentMethodType) || { prefix: ""};
    return payViaType.prefix;
};

export const getPaymentMethodType = (paymentGateway = "") => {
    if (paymentGateway === PAYMENT_GATEWAY.poli) {
        return PAYMENT_TYPE.INTERNET_BANKING.label;
    }
    if (PAYMENT_GATEWAY[paymentGateway] && (PAYMENT_TYPE[paymentGateway] || {}).label) {
        return PAYMENT_TYPE[paymentGateway].label;
    }
    return PAYMENT_TYPE.CARD.label;
};

export const getOptions = (text = "year", from = 1, upto = NUMBER.TWENTY, labelKey = "label", valueKey = "value") => {
    return range(from, upto).map(item => {
        let label = `${item} ${item === 1 ? text : `${text}s`}`;
        if (item === 0 && (text === "year" || text === "month")) {
            label = `Less than a ${text}`;
        }
        return { [labelKey]: label, [valueKey]: item};
    });
};

export const getMonthOptions = () => {
    return monthNames.map(month => {
        return {
            label: month,
            value: month.toUpperCase()
        };
    });
};

const presentYear = new Date().getFullYear();

export const getYearOptions = (from = presentYear, upto = NUMBER.FIFTY) => {
    const options = [];
    for (let i = from; i > 0; i--) {
        if (from - upto <= i) {
            options.push(({ label: i, value: i}));
        }
    }

    return options;
};

export const getMonthNumberFromMonthName = (month = "") => {
    return monthNamesValues.indexOf(month);
};

export const getNumberOptions = (from = 1, upto = NUMBER.TWENTY, labelKey = "label", valueKey = "value") => {
    return range(from, upto).map(item => ({ [labelKey]: `${item}`, [valueKey]: item}));
};

export const getYearInBackOrder = (currentYear = parseInt(new Date().getFullYear()), from = 0, upto = NUMBER.FOURTEEN, labelKey = "label", valueKey = "value") => {
    return range(from, upto).map((item) => ({ [labelKey]: (currentYear - item).toString(), [valueKey]: currentYear - item}));
};

export const getSelectedOption = (list, selectedValue, selectedKey = "value") => {
    const item = list.find(i => i[selectedKey].toString() === (selectedValue || "").toString());
    return item ? item : null;
};

export const getPreApprovedPayload = (params, state) => {
    const {
        user: { secureToken, firstName, middleName, lastName, mobile, email },
        checkout: { order: { orderId } },
        carDetails: { content }
    } = state;

    const {  make, model } = content;

    const carDetailsPagePath = getCarDetailsPageURL(content);

    const payload = {
        type: "pre_approved_lending",
        data: {
            addressType: "EMAIL",
            countryCode: X_COUNTRY_CODE.AU,
            to: ["hello.au@cars24.com"],
            variables: {
                ...params,
                landerName: params.lenderName,
                make,
                model,
                email,
                link: `${appUrl()}${carDetailsPagePath}`,
                phone: mobile,
                customerName: `${firstName || ""} ${middleName || ""} ${lastName || ""}`
            }
        }
    };

    return { payload, secureToken, orderId };
};

export const getTransactionAmount = (data, totalAmount) => {
    let totalAmountPaid = 0;
    data.forEach(item => {
        if (item.status === PAYMENT_HISTORY_STATUS.SUCCESS) {
            totalAmountPaid += item.amount;
        }
    });
    return ({totalAmountPaid, remainingAmount: totalAmount - totalAmountPaid});
};

export const getStateCode = (state) => {
    return state?.stateCode?.split("_")[1];
};

export const getCarFirstFormHeaderTitle = (checkoutEditMode, testDriveFlow) => {
    if (checkoutEditMode) {
        return "Edit Financing Details";
    } else if (testDriveFlow) {
        return "Get pre-approval";
    }
    return "Apply for finance";
};

export const objectToQueryString = (params = {}) => {
    return Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null) // Exclude undefined or null values
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&");
};

export const filterCartItems = (cartItems = [], keys = []) => {
    const defaultKeys = ["EXTENDED_WARRANTY_PLAN", "CAR_SERVICING", "CAR_COVER_BUNDLES"];
    const keysToFilter = keys.length ? keys : defaultKeys;
    return cartItems.filter(item => keysToFilter.includes(item.categoryKey));
};

export const filterExcludedItems = (cartItems = [], keys = []) => {
    const excludedKeys = ["EXTENDED_WARRANTY_PLAN", "CAR_SERVICING", "CAR_COVER_BUNDLES"];
    const keysToFilter = keys.length ? keys : excludedKeys;
    return cartItems.filter(item => !keysToFilter.includes(item.categoryKey));
};
