import React from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

const FormikForm = ({
    children,
    onClickSubmit,
    initialValues,
    formValidator,
    validate
}) => {
    const props = {
        enableReinitialize: true,
        initialValues,
        onSubmit: onClickSubmit,
        ...(validate ? { validate } : { validationSchema: formValidator })
    };

    return (
        <Formik

            {...props}
        >
            {(params) => (
                <Form>
                    {children && children(params)}
                </Form>
            )}
        </Formik>
    );
};

FormikForm.propTypes = {
    children: PropTypes.func,
    onClickSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    formValidator: PropTypes.object,
    validate: PropTypes.func
};

export default FormikForm;
