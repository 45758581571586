import React from "react";
import Button from "../../shared/button";
import PropTypes from "prop-types";
import styles from "./styles.css";

const FormFooter = ({
    currentFormData,
    onClickBack,
    onClickNext,
    isNextLoading,
    children
}) => {
    return (
        <React.Fragment>
            {children}
            <div styleName={"styles.buttonContainer"}>
                <div styleName={"styles.backButton"}>
                    <Button onClick={onClickBack} type="back" text={currentFormData.backButtonCTA} />
                </div>
                <div styleName={"styles.nextButton"}>
                    <Button onClick={onClickNext} type="submit" isLoading={isNextLoading} text={currentFormData.nextButtonCTA} />
                </div>
            </div>
        </React.Fragment>
    );
};

FormFooter.propTypes = {
    currentFormData: PropTypes.object,
    children: PropTypes.array,
    onClickBack: PropTypes.func,
    onClickNext: PropTypes.func,
    isNextLoading: PropTypes.bool
};

export default FormFooter;

