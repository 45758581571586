import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const ErrorMsg = ({
    isError,
    errorMsg = "Please enter a valid response to this question"
}) => {
    return (
        isError ? <div styleName={"styles.errorMsg"}>{errorMsg}</div> : ""
    );
};

ErrorMsg.propTypes = {
    errorMsg: PropTypes.string,
    isError: PropTypes.bool
};

export default ErrorMsg;
