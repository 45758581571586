/* eslint-disable complexity */
/* eslint-disable max-statements */
import Init from "./init";
import { appUrl } from "../../constants/url-constants";
import qs from "querystring";
// import { FILTER_ENTITIES } from "./constants";
import parseLocationSearchParams from "../helpers/parse-location-search-params";
import { DEFAULT_CITY, COUNTRY, FINANCE_CENTRIC_CONSTANTS } from "../../constants/app-constants";
import getAppliedFilter from "./get-applied-filter";
import { FILTER_ENTITIES } from "./constants";
import dashedLowercase from "../helpers/dashed-lowercase";
import parseCookie from "../helpers/parse-cookie";
import getGaid from "../helpers/get-gaid";
import { getQueryParams } from "../helpers/get-query-params";

class Filters extends Init {
    constructor({ cityCode, cityName }) {
        super();
        this._cityCode = cityCode || DEFAULT_CITY.AU.code;
        this.cityName = cityName || COUNTRY.name;
        this.entireMakeSelected = false;
        this._ignoredUrlOptions = [
            "serveWarrantyCount",
            "gaId",
            "mobile",
            "listingCohortABTest"
        ];
    }

    normalizeCity() {
        try {
            return this._city.toLowerCase().split(" ").join("-");
        } catch (e) {
            return this._defaultCity;
        }
    }

    getRedirectURL(entities, data) {
        const url = ["buy", "used"];
        if (Array.isArray(entities)) {
            this.getPriorityOrder().forEach((item) => {
                if (entities.includes(item)) {
                    url.push(...(this.getEntityModifier(item))(data));
                }
            });
        } else {
            url.push("cars");
        }
        url.push(this.normalizeCity());
        return url.join("-");
    }

    getRelativeURL() {
        return `/${this.getRedirectURL(...arguments)}/`;
    }

    getAbsoluteURL() {
        return `${appUrl()}/${this.getRedirectURL(...arguments)}/`;
    }

    /*
    single filter, single option,
    single filter, multiple option,
    single filter, single option, single sub option,
    single filter, single option, multiple sub option,
    single filter, multiple option, multiple sub option

    &sf=make:Honda-sub-model:Honda Activa-or-make:Bajaj-sub-model:Bajaj Pulsar 150-or-make:Hero
    &rf=year:2010;2015

    {
        sf: [

        ]
    }

    */

    // getAppliedFilterString(appliedFilters, filters) {
    //     appliedFilters.forEach((appliedFilter) => {
    //         const selectedFilter = filters[appliedFilter.filterKey];
    //     });
    // }

    getOptionArray(currentFilter, appliedFiltersList) {
        const optionsArray = [];
        ((currentFilter && currentFilter.options) || []).forEach((filterOption) => {
            let appliedOptionString = "";
            const selectedAppliedFilter = getAppliedFilter({ filterKey: currentFilter.key, optionKey: filterOption.key }, appliedFiltersList);
            if (selectedAppliedFilter) {
                appliedOptionString = `${currentFilter.key}:${filterOption.key}`;
            }
            if (filterOption.subFilter) {
                const subFilterOptionList = [];
                filterOption.subFilter.options.forEach((subFilterOption) => {
                    if (getAppliedFilter({ filterKey: currentFilter.key, optionKey: filterOption.key, subOptionKey: subFilterOption.key }, appliedFiltersList)) {
                        subFilterOptionList.push(subFilterOption.key);
                    }
                });
                if (subFilterOptionList.length) {
                    appliedOptionString = `${currentFilter.key}:${filterOption.key}-sub-${filterOption.subFilter.key}:${subFilterOptionList.join(";")}`;
                    if (currentFilter.key === "make") {
                        const obj = currentFilter.options.find(item => item.key === filterOption.key);
                        if (subFilterOptionList.length === obj.subFilter.options.length) {
                            this.entireMakeSelected = true;
                        }
                    }
                }
            }
            if (appliedOptionString) {
                optionsArray.push(appliedOptionString);
            }
        });

        return optionsArray;
    }

    getRfFilterKey(key, option, filters) {
        if (key === FILTER_ENTITIES.DELIVERY_TIME) return `${filters.deliveryTime.deliveryCityKey}:${option.min};${option.max}`;
        return `${key}:${option.min};${option.max}`;
    }

    getFilterString(appliedFiltersList, appliedSuggestionsList, filters) {
        let filterString = "";
        const appliedFilters = [];
        Object.keys(filters).map((filterKey) => {
            let filterOptionArray = [];
            const currentFilter = filters[filterKey];
            if (currentFilter.filterType === "rf") {
                const selectedAppliedFilter = getAppliedFilter({ filterKey }, appliedFiltersList);
                if (selectedAppliedFilter) {
                    const filterOption = this.getRfFilterKey(filterKey, selectedAppliedFilter, filters);
                    filterOptionArray.push(filterOption);
                }
                const selectedAppliedSuggestion = getAppliedFilter({ filterKey }, appliedSuggestionsList);
                if (selectedAppliedSuggestion) {
                    const filterOption = this.getRfFilterKey(filterKey, selectedAppliedSuggestion, filters);
                    filterOptionArray.push(filterOption);
                }
            } else if (currentFilter.options || currentFilter.suggestions) {
                if (currentFilter.options) {
                    filterOptionArray = [
                        ...filterOptionArray,
                        ...this.getOptionArray(currentFilter, appliedFiltersList)
                    ];
                }
                if (currentFilter.suggestions) {
                    filterOptionArray = [
                        ...filterOptionArray,
                        ...this.getOptionArray(currentFilter, appliedSuggestionsList)
                    ];
                }
            }
            if (filterOptionArray.length) {
                appliedFilters.push(`${currentFilter.filterType}=${filterOptionArray.join("-or-")}`);
            }
        });
        filterString = appliedFilters.join("&");
        return filterString;
    }

    getCategoryPayload(categories = []) {
        const urlString = categories.reduce((acc, value) => {
            acc = `${acc + value}`;
            return acc;
        }, `category=`);
        return urlString;
    }
    getListingPayload({ selectedFilters = [], selectedSuggestions = [], filters = {}, options = [], categories = [], spath, isFinanceCentricLayout = false}) {
        const apiPayload = [this.getFilterString(selectedFilters, selectedSuggestions, filters, categories)];
        const urlPayload = [...apiPayload];
        const gaId = parseCookie("_ga");
        const DEFAULT_PATH = "/buy-used-cars";
        const {fc = false, showClpCrmVariant = null} = getQueryParams() || {};
        if (options) {
            const filteredOptions = { ...options };
            this._ignoredUrlOptions.forEach(i => delete filteredOptions[i]);
            urlPayload.push(qs.stringify(filteredOptions));
            apiPayload.push(qs.stringify(options));
        }

        if ((isFinanceCentricLayout || fc) && selectedFilters.length) {
            urlPayload.push(qs.stringify({
                [FINANCE_CENTRIC_CONSTANTS.QUERY_PARAM]: "true"
            }));
        }

        const gaIdPayload = `sf=gaId:${gaId}`;
        const cityPayload = `sf=city:${this._cityCode}`;
        const categoryPayload = this.getCategoryPayload(categories);
        urlPayload.push(gaIdPayload);
        urlPayload.push(cityPayload);
        let urlString = urlPayload.filter(payload => !!payload).join("&");
        apiPayload.push(getGaid(gaIdPayload));
        apiPayload.push(cityPayload);
        let payloadString = apiPayload.filter(payload => !!payload).join("&");
        if (categories.length) {
            urlPayload.push(categoryPayload);
            urlString = urlPayload.filter(payload => !!payload).join("&");
            apiPayload.push(categoryPayload);
            payloadString = apiPayload.filter(payload => !!payload).join("&");
        }
        urlString = `${urlString}&entireMakeSelected=${this.entireMakeSelected}${showClpCrmVariant ? `&showClpCrmVariant=${ showClpCrmVariant}` : ""}`;
        payloadString = `${payloadString}&entireMakeSelected=${this.entireMakeSelected}${showClpCrmVariant ? `&showClpCrmVariant=${ showClpCrmVariant}` : ""}`;

        const relativeURL = `${spath || DEFAULT_PATH}${!spath && this.cityName ? `-${dashedLowercase(this.cityName, "-")}` : ""}?${urlString}`;
        return {
            relativeURL,
            payload: payloadString,
            absoluteURL: `${appUrl()}${relativeURL}${this.cityName ? `-${dashedLowercase(this.cityName, "-")}` : ""}?${urlString}`
            // filterCount,
            // nonRangedfilterCount
        };
    }

    getPaginationUrl({ history, page }) {
        const queryParams = parseLocationSearchParams(history.location.search);
        if (page <= 1) {
            delete queryParams.page;
        } else {
            queryParams.page = page;
        }

        const relativeURL = [
            history.location.pathname,
            qs.stringify(queryParams)
        ].filter(i => !!i).join("?");
        const absoluteURL = `${appUrl()}${relativeURL}`;

        return { absoluteURL, relativeURL, payload: relativeURL };
    }

    removeQueryParams(path, key, value = "") {
        const [basePath, queryString] = path.split("?");
        const params = new window.URLSearchParams(queryString);
        params.set(key, value);
        return `${basePath}?${params.toString()}`;
    }
}

export default Filters;
