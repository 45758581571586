/* eslint-disable max-params */
import { X_VEHICLE_TYPE } from "../constants/api-constants";
import formQueryParams from "../utils/helpers/form-query-params";
import { getDeviceType } from "../utils/helpers/get-device-type";

import { API_PATH_CONSTANTS } from "./api-path-constants";
import { API_VERSION_V1, API_VERSION_V2 } from "./api-version";
import {captureException} from "@sentry/react";

// eslint-disable-next-line max-statements
export default (api) => {

    const deviceType = getDeviceType();
    const fetchFinanceDetail = (params) => {
        return api.get(`/finance`, { params });
    };

    const getOrderSummary = (appointmentId, token) => {
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${appointmentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const getOrderSummaryByUserId = (appointmentId, params) => {
        return api.get(`${API_VERSION_V1}client/${API_PATH_CONSTANTS.ORDER}/appointmentId/${appointmentId}`, {
            headers: {
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            },
            params
        });
    };

    const createOrder = (params, token, source, customHeader = {}) => {
        return api.post(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: source,
                ...customHeader
            }
        });
    };

    const updateFinanceDetail = (order, orderId, token) => {
        const {apiSource = deviceType} = order || {};
        if (!orderId) {
            const error = new Error("orderId is required");
            captureException(error);
            return Promise.reject(error);
        }
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/finance`, {...order}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: apiSource
            }
        });
    };

    const updateDeliveryMode = (order, orderId, token) => {
        if (!orderId) {
            const error = new Error("orderId is required");
            captureException(error);
            return Promise.reject(error);
        }
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/address`, {...order}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const updateFFCheckoutVariant = (orderId, payload, token) => {
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/variants/${orderId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };
    const updateDeliveryContract = (order, orderId, token) => {
        if (!orderId) {
            const error = new Error("orderId is required");
            captureException(error);
            return Promise.reject(error);
        }
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/user-purpose `, {...order}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };
    const updateFinancePersonalDetail = (params, orderId, token) => {
        return api.post(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/finance/offer`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };
    const getPaymentStatus = (orderId, token) => {
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/au/payment/status`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const initiateOrderPayment = (orderId, payload, token) => {
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/au/payments`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
                // SOURCE: deviceType
            }
        });
    };
    const getAdyenFailure = (orderId, params, token) => {
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/adyen-status`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
                // SOURCE: deviceType
            },
            params
        });
    };

    const updateOrderBooking = (orderId, token) => {
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/book`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const getMyBookings = (token, params) => {
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/user/app`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            },
            params
        });
    };

    const getLoanOfferDetails = (orderId, token) => {
        return api.get(`${API_VERSION_V1}quote/price/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const createLoanOffer = (params, orderId, token) => {
        return api.post(`${API_VERSION_V1}quote/price/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };
    const updateLoanOffer = (params, orderId, token) => {
        return api.put(`${API_VERSION_V1}quote/price/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const requestCallBack = (params, orderId, token) => {
        return api.post(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/support/callback`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getTradeInDetails = (token) => {
        return api.get(`${API_VERSION_V1}trade/offer`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getTradeInDetailsV2 = (token) => {
        return api.get(`c2b/trade/offer`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const createTradeIn = (params, orderId, token) => {
        return api.post(`${API_VERSION_V1}trade/offer/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };
    const updateTradeIn = (params, orderId, token) => {
        return api.put(`${API_VERSION_V1}trade/offer/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const createTradeInTopFunnel = (params, token) => {
        return api.post(`${API_VERSION_V1}trade/pre-checkout/offer`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const updateTradeInTopFunnel = (params, token) => {
        return api.put(`${API_VERSION_V1}trade/pre-checkout/offer`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getTradeInStates = (token) => {
        return api.get(`${API_VERSION_V1}trade/states`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getCarMakeModel = (token) => {
        return api.get(`${API_VERSION_V1}trade/brands/all/models?infoSource=KBB`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getVariants = (token, params, isV1VersionAPI = false) => {
        return api.get(`${isV1VersionAPI ? API_VERSION_V1 : API_VERSION_V2}trade/details-by-make?infoSource=KBB&brandId=${params.brands}&modelId=${params.brandModelMap}&year=${params.showYear}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const addBYODetails = (token, params) => {
        return api.post(`${API_VERSION_V1}consumer/byof`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };
    const getBYODetails = (token, orderId) => {
        return api.get(`${API_VERSION_V1}consumer/byof?orderId=${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };
    const updateBYODetails = (token, params) => {
        return api.put(`${API_VERSION_V1}consumer/byof`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getCurrentOrderLoan = (token, orderId) => {
        return api.get(`${API_VERSION_V1}${orderId}/summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getPostOrderTradeDetail = (token, orderId) => {
        return api.get(`${API_VERSION_V1}trade/booking/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const updateSplitPayment = (params, orderId, token) => {
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/splitPayment`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const getPaymentHistory = (orderId, token) => {
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/au/payment/transactions`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const getPaymentSummary = (orderId, token) => {
        if (!orderId) {
            const error = new Error("orderId is required");
            captureException(error);
            return Promise.reject(error);
        }
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/payment-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const getSignContract = (orderId, token) => {
        return api.get(`${API_VERSION_V1}docsign/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const postSignContract = (params, token) => {
        return api.put(`${API_VERSION_V1}docsign/envelope`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateSignContractStatus = (params, token) => {
        return api.put(`${API_VERSION_V1}docsign/envelope/pushback`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const createC2bTrade = (token, params) => {
        return api.post(`/c2b/trade/order`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };
    const getC2bTrade = (token) => {
        return api.get(`/c2b/trade/unbooked`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getColdLeadDetails = (orderId) => {
        return api.get(`/sell/car/order/${orderId}`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getOrderDetails = (token, orderId) => {
        return api.get(`/c2b/trade/order/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const updateC2bTrade = (token, params, orderId) => {
        return api.put(`/sell/car/order/${orderId}`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const acceptC2bTrade = (token, orderId, payload) => {
        return api.put(`/sell/car/order/${orderId}/accept`, {...payload}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const updateBasicDetails = (token, params, orderId) => {
        return api.put(`/c2b/trade/order/${orderId}/user/details`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getSellOrderDetails = (token, params) => {
        return api.get(`/c2b/trade/order`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };
    const getBookedC2bTrade = (token) => {
        return api.get(`/c2b/trade/booked`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const c2bRequestCallBack = (token, params) => {
        return api.post(`${API_VERSION_V1}support/callback`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getPreApprovalLoanDetails = (uuid, token) => {
        return api.get(`v1/preapproval/details/${uuid}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getTradeInVehicleDetails = (token, params) => {
        /* For getting vehicle data [array] we need to make post call with reg or vin */
        return api.post(`${API_VERSION_V1}trade/vehicle`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getPreApprovalLoanDetailsAuthenticated = (uuid, token) => {
        return api.get(`v1/preapproval/me`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const createPreApprovalLoan = (token, params) => {
        return api.post(`${API_VERSION_V1}preapproval/create`, params, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updatePreApprovalLoan = (token, params) => {
        return api.put(`${API_VERSION_V1}preapproval/update`, {...params}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updatePreApprovalSelectedOffer = (token, params) => {
        return api.put(`${API_VERSION_V1}preapproval/offer`, {...params}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getDepositRangeValue = () => {
        return api.get(`${API_VERSION_V1}deposit/range`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getC2BTradeInVehicleDetails = (token, params) => {
        return api.post(`/c2b/trade/vehicles`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const updateBatchPrice = (token) => {
        return api.put(`${API_VERSION_V1}preapproval/batch-price/update`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const hubSpotBasicUpdate = (orderId, token, params) => {
        if (!orderId) {
            const error = new Error("orderId is required");
            captureException(error);
            return Promise.reject(error);
        }
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/basic-details`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: params.apiSource || deviceType
            }
        });
    };

    const getCheckoutSteps = (token, orderId) => {
        return api.get(`${API_VERSION_V1}checkout-journey/step/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const createCheckoutSteps = (params, token, source) => {
        return api.post(`${API_VERSION_V1}checkout-journey/step`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: source
            }
        });
    };

    const updateCheckoutSteps = (params, token, source) => {
        return api.put(`${API_VERSION_V1}checkout-journey/step/${params.orderId}`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: source
            }
        });
    };

    const drivaPollingApi = (params, token) => {
        return api.post(`${API_VERSION_V1}driva/poll`, {...params}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const drivaCallbackApi = (params, token) => {
        return api.post(`${API_VERSION_V1}driva/support/callback`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getDrivaRoiDetails = (token, params = "") => {
        return api.get(`${API_VERSION_V1}roi-details${params}`, {
            headers: {
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const applyCoupon = (token, orderId, params) => {
        return api.post(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/applyCoupons`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
                // SOURCE: deviceType
            }
        });
    };

    const removeCoupon = (token, orderId, params) => {
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/removeCoupons`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
                // SOURCE: deviceType
            }
        });
    };

    const getWarrantyPlans = (token, params = "") => {
        return api.get(`${API_VERSION_V1}extended-warranty/plans${params}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateWarrantyPlans = (token, orderId, params) => {
        if (!orderId) {
            const error = new Error("orderId is required");
            captureException(error);
            return Promise.reject(error);
        }
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/extendedWarranty`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
                // SOURCE: deviceType
            }
        });
    };

    const getTestDriveDate = (token, stateCode = "") => {
        return api.get(`${API_VERSION_V1}test-drive/slots?stateCode=${stateCode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const checkEligibilityBFF = (token, params = {}) => {
        return api.post(`/v1/finance/eligibility`, params, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getMyTestDriveBookings = (token, params) => {
        return api.get(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/user/all/test-drive`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            },
            params
        });
    };

    const updateOrder = (token, orderId, params) => {
        return api.put(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/${orderId}/update${formQueryParams(params)}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const createOrUpdateInHouseLoanOffer = (params, orderId, token) => {
        return api.post(`/v1/finance/quote/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const submitInHouseLoanOffer = (params, orderId, token) => {
        return api.post(`/v1/finance/submit/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getInHouseLoanOfferDetails = (orderId, token) => {
        return api.get(`/v1/finance/quote/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getTestDriveData = (token, params = {}) => {
        return api.get(`${API_VERSION_V1}test-drive${formQueryParams(params)}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const selectInHouseLoanOffer = (orderId, offerId, token) => {
        return api.post(`/v1/finance/selected/${orderId}/${offerId}`, { }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const selectInHouseLoanOfferV2 = (orderId, offerId, token, selectedCycle = "") => {
        return api.post(`/v1/finance/selected-offers/${orderId}/${offerId}?selectedCycle=${selectedCycle}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const inHouseFinancePollingApi = (orderId, token, params) => {
        return api.get(`/v1/finance/poll-offers/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            },
            params
        });
    };

    const createOrderForSimilarVehichle = (token, payload) => {
        return api.post(`${API_VERSION_V1}${API_PATH_CONSTANTS.ORDER}/basic-details`, {...payload}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };
    const getVerificationStatus = (orderId, token) => {
        return api.get(`/v1/dl/status/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getSeoContent = (payload = {}) => {
        return api.post(`/c2b/trade/config`, {...payload}, {
            headers: {
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const registerVerificationStatus = (token, orderId, verificationId, verificationToken = "") => {
        return api.put(`/v1/dl/verify`, {orderId, verificationId: verificationToken, verificationToken: verificationId}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const fetchInspectionSlots = (orderId, params) => {
        return api.get(`/appointment/${orderId}/slot`, {
            params,
            headers: {
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const bookInspectionSlot = (token, reqObj) => {
        return api.post(`/appointment/slot`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const cancelInspectionSlot = (token, orderId, reqObj) => {
        return api.put(`/appointment/${orderId}/slot/cancel`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateMakeModelFlow = (token, reqObj, orderId) => {
        return api.put(`/sell/car/order/${orderId}/mmvy`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };
    const updateMakeModelFlowForMarketplace = (token, reqObj) => {
        return api.put(`/sell/car/sourcing/update`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getInspectionDetails = (token, orderId) => {
        return api.get(`/c2b/trade/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const fetchCancellationReason = (token) => {
        return api.get(`/v1/trade/feedback`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const postZeroDpPaymentApi = (token, params) => {
        return api.post(`/v1/order/zero-dp`, params, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const uploadPaymentProof = (token, orderId, reqObj) => {
        return api.put(`/v1/order/${orderId}/proof`, reqObj, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const postReBookZeroDpOrder = (token, params) => {
        return api.post(`/v1/order/rebook`, params, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    const confirmBankTransfer = (token, orderId, reqObj) => {
        return api.put(`/v1/order/${orderId}/confirm-ib`, reqObj, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const downloadContact = (token, orderId) => {
        return api.get(`/v1/docsign/${orderId}/contract`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getBankDetails = (token, orderId) => {
        return api.get(`/v1/order/${orderId}/bank-details/`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const updateC2bOrder = (token, orderId, data) => {
        return api.put(`/sell/car/update/order/${orderId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const uploadDrivingLicence = (params, payload) => {
        const { token, orderId, type } = params;
        return api.post(`/driving-licence/${orderId}/upload?type=${type}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                ...(token ? { Authorization: `Bearer ${token}` } : {})
            }
        });
    };

    const getLicenceUploadStatusByOrderId = ({token, orderId}) => {
        return api.get(`/driving-licence/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const saveDrivingLicenceDetails = (params, payload) => {
        const { token, orderId } = params;
        return api.put(`/driving-licence/${orderId}`, payload, {
            headers: {
                ...(token ? { Authorization: `Bearer ${token}` } : {})
            }
        });
    };

    const downloadLicenceImage = (token, orderId, type) => {
        return api.get(`/driving-licence/${orderId}/download?type=${type}`, {
            headers: {
                ...(token ? { Authorization: `Bearer ${token}` } : {})
            }
        });
    };

    return {
        fetchFinanceDetail,
        updateFinancePersonalDetail,
        getOrderSummary,
        createOrder,
        updateFinanceDetail,
        updateDeliveryMode,
        getPaymentStatus,
        initiateOrderPayment,
        updateOrderBooking,
        getMyBookings,
        createLoanOffer,
        updateLoanOffer,
        getLoanOfferDetails,
        requestCallBack,
        getTradeInDetails,
        createTradeIn,
        updateTradeIn,
        createTradeInTopFunnel,
        updateTradeInTopFunnel,
        getTradeInStates,
        addBYODetails,
        getBYODetails,
        updateBYODetails,
        getCurrentOrderLoan,
        getPostOrderTradeDetail,
        updateSplitPayment,
        getPaymentHistory,
        getSignContract,
        postSignContract,
        updateSignContractStatus,
        createC2bTrade,
        getC2bTrade,
        updateC2bTrade,
        getBookedC2bTrade,
        c2bRequestCallBack,
        getPreApprovalLoanDetails,
        createPreApprovalLoan,
        updatePreApprovalLoan,
        updatePreApprovalSelectedOffer,
        getDepositRangeValue,
        getPreApprovalLoanDetailsAuthenticated,
        getTradeInVehicleDetails,
        getC2BTradeInVehicleDetails,
        updateBatchPrice,
        hubSpotBasicUpdate,
        getCheckoutSteps,
        createCheckoutSteps,
        updateCheckoutSteps,
        drivaPollingApi,
        drivaCallbackApi,
        getDrivaRoiDetails,
        applyCoupon,
        removeCoupon,
        getWarrantyPlans,
        updateWarrantyPlans,
        getTestDriveDate,
        getMyTestDriveBookings,
        updateOrder,
        getTestDriveData,
        checkEligibilityBFF,
        createOrUpdateInHouseLoanOffer,
        submitInHouseLoanOffer,
        getInHouseLoanOfferDetails,
        selectInHouseLoanOffer,
        inHouseFinancePollingApi,
        createOrderForSimilarVehichle,
        getVerificationStatus,
        registerVerificationStatus,
        selectInHouseLoanOfferV2,
        getCarMakeModel,
        getVariants,
        getSeoContent,
        getSellOrderDetails,
        acceptC2bTrade,
        updateBasicDetails,
        getColdLeadDetails,
        getOrderDetails,
        fetchInspectionSlots,
        bookInspectionSlot,
        cancelInspectionSlot,
        getInspectionDetails,
        fetchCancellationReason,
        postZeroDpPaymentApi,
        postReBookZeroDpOrder,
        uploadPaymentProof,
        confirmBankTransfer,
        downloadContact,
        updateMakeModelFlow,
        getTradeInDetailsV2,
        getBankDetails,
        updateDeliveryContract,
        getPaymentSummary,
        getAdyenFailure,
        updateC2bOrder,
        updateFFCheckoutVariant,
        getOrderSummaryByUserId,
        updateMakeModelFlowForMarketplace,
        uploadDrivingLicence,
        getLicenceUploadStatusByOrderId,
        saveDrivingLicenceDetails,
        downloadLicenceImage
    };
};
