// import CheckoutBasicDetails from "../components/au.mobile/checkout-basic-details";
// import CheckoutDeliveryInformation from "../components/au.mobile/checkout-delivery-information";
// import CheckoutPaymentOptions from "../components/au.mobile/checkout-payment-options";
// import CheckoutSummary from "../components/au.mobile/checkout-summary";
// import OrderConfirmedInfo from "../components/au.mobile/order-confirmed-info";
// import GetFinancing from "../components/au.mobile/get-financing";
// import FinanceLoanDetails from "../components/au.mobile/finance-loan-details";
// import FinanceLoanType from "../components/au.mobile/finance-loan-type";
// import FinancePersonalDetails from "../components/au.mobile/finance-personal-details";
// import FinanceEmploymentDetails from "../components/au.mobile/finance-employment-details";
// import FinanceSelectOffer from "../components/au.mobile/finance-select-offer";
// import PreApprovedLoan from "../components/au.mobile/pre-approved-loan";

import loadable from "@loadable/component";
const BSBBankTransfer  = loadable(() => import("../components/au.mobile/bank-transfer"));

const CheckoutBasicDetails = loadable(() => import("../components/au.mobile/checkout-basic-details"));
const CheckoutDeliveryInformation = loadable(() => import("../components/au.mobile/checkout-delivery-information"));
// const CheckoutPaymentOptions = loadable(() => import("../components/au.mobile/checkout-payment-options"));
// const CheckoutSummary = loadable(() => import("../components/au.mobile/checkout-summary-revamp-v2"));
// const CheckoutSummary = loadable(() => import("../components/au.mobile/checkout-summary-revamp-v3"));
const CheckoutSummaryAB = loadable(() => import("../components/au.mobile/checkout-summary-ab"));
const OrderConfirmedInfo = loadable(() => import("../components/au.mobile/order-confirmed-info"));
const GetFinancing = loadable(() => import("../components/au.mobile/get-financing"));
const FinanceLoanDetails = loadable(() => import("../components/au.mobile/finance-loan-details"));
const FinancePersonalDetails = loadable(() => import("../components/au.mobile/finance-personal-details"));
const FinanceEmploymentDetails = loadable(() => import("../components/au.mobile/finance-employment-details"));
const DrivaCustomizeWrapper = loadable(() => import("../components/au.mobile/driva-customize-wrapper"));
const PreApprovedLoan = loadable(() => import("../components/au.mobile/pre-approved-loan"));
const TradeInIntro = loadable(() => import("../components/au.mobile/trade-in-intro"));
const TradeInSelectCar = loadable(() => import("../components/au.mobile/trade-in-select-car"));
const TradeInQuestionnaire = loadable(() => import("../components/au.mobile/trade-in-questionnaire"));
const TradeInValuation = loadable(() => import("../components/au.mobile/trade-in-valuation"));
const TradeInValuationHigh = loadable(() => import("../components/au.mobile/trade-in-valuation-high"));
const PaymentOverview = loadable(() => import("../components/au.mobile/split-payments"));
const CheckoutOverview = loadable(() => import("../components/au.mobile/checkout-steps-overview"));
const CheckoutPaymentOptionRevamp = loadable(() => import("../components/au.mobile/checkout-payment-option-revamp"));
const TradeInCarmake = loadable(() => import("../components/au.mobile/trade-in-carmake"));
const Cars24Cover  = loadable(() => import("../components/au.mobile/cars24-cover"));
const AddOns  = loadable(() => import("../components/au.mobile/add-ons"));
const ZeroDpReBookDeposit = loadable(() => import("../components/au.mobile/zero-dp-re-book-deposit"));
const PreApprovedCheckoutBasic = loadable(() => import("../components/au.mobile/finance-first-basic-details"));
const PreApprovedAddOns  = loadable(() => import("../components/au.mobile/finance-first-checkout-add-on"));
const PreApprovedDelivery  = loadable(() => import("../components/au.mobile/finance-first-delivery-edit"));
const PreApprovedPickUp  = loadable(() => import("../components/au.mobile/finance-first-pick-up-edit"));
const PreApprovedExtendedWarranty  = loadable(() => import("../components/au.mobile/finance-first-checkout-extended-warranty"));

export const checkoutRoutes = {
    overview: {
        key: "overview",
        name: "Checkout steps overview",
        route: "overview",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "",
        component: CheckoutOverview,
        hideCurrentPill: true,
        useRoute: null,
        type: "checkout",
        showEgcPrice: true,
        hideShippingPrice: true
    },
    basicDetails: {
        key: "basicDetails",
        name: "Basic details",
        route: "basic-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "basic_details",
        defaultBack: "",
        defaultNext: "delivery-details",
        component: CheckoutBasicDetails,
        hideCurrentPill: false,
        useRoute: null,
        type: "checkout",
        approx: 3,
        showEgcPrice: true,
        hideShippingPrice: true
    },
    deliveryDetails: {
        key: "deliveryDetails",
        name: "Delivery details",
        route: "delivery-details",
        gtmPageName: "Delivery",
        gtmPillClickLabel: "delivery_details",
        defaultBack: "basic-details",
        defaultNext: "trade-in-intro",
        component: CheckoutDeliveryInformation,
        hideCurrentPill: false,
        useRoute: null,
        type: "checkout",
        approx: 2,
        showEgcPrice: false
    },
    //Trade-In
    tradeInIntro: {
        key: "tradeInIntro",
        name: "Trade in",
        route: "trade-in-intro",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "delivery-details",
        component: TradeInIntro,
        hideCurrentPill: false,
        useRoute: "trade-in",
        type: "checkout",
        approx: 2
    },
    tradeInCarDetails: {
        key: "tradeInCarDetails",
        name: "Trade in",
        route: "trade-in-car-details",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "trade-in-intro",
        component: TradeInSelectCar,
        hideCurrentPill: true,
        useRoute: "trade-in",
        type: "checkout",
        approx: 2
    },
    tradeInCarMake: {
        key: "tradeInCarMake",
        name: "Trade in",
        route: "trade-in-car-make",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "trade-in-intro",
        component: TradeInCarmake,
        hideCurrentPill: true,
        useRoute: "trade-in",
        type: "checkout",
        approx: 2
    },
    tradeInQuestionnaire: {
        key: "tradeInQuestionnaire",
        name: "Trade in",
        route: "trade-in-questionnaire",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-valuation",
        defaultBack: "trade-in-intro",
        component: TradeInQuestionnaire,
        hideCurrentPill: true,
        useRoute: "trade-in",
        type: "checkout",
        approx: 2
    },
    tradeInValuation: {
        key: "tradeInValuation",
        name: "Trade in",
        route: "trade-in-valuation",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "payment-options",
        defaultBack: "trade-in-questionnaire",
        component: TradeInValuation,
        hideCurrentPill: true,
        useRoute: "trade-in",
        type: "checkout",
        approx: 2
    },
    tradeInValuationHigh: {
        key: "tradeInValuationHigh",
        name: "Checkout",
        route: "trade-in-valuation-high",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "order-confirmation",
        defaultBack: "trade-in-valuation",
        component: TradeInValuationHigh,
        hideCurrentPill: true,
        useRoute: "checkout",
        type: "checkout"
    },
    carCover: {
        key: "carCover",
        name: "Cars24 cover",
        route: "warranty-cover",
        gtmPageName: "carCover",
        gtmPillClickLabel: "warranty_cover_details",
        defaultBack: "trade-in-intro",
        defaultNext: "add-ons",
        component: Cars24Cover,
        hideCurrentPill: false,
        useRoute: null,
        type: "checkout",
        approx: 2,
        showEgcPrice: false
    },
    addOns: {
        key: "addOns",
        name: "Add ons",
        route: "add-ons",
        gtmPageName: "addOns",
        gtmPillClickLabel: "addOns",
        defaultBack: "warranty-cover",
        defaultNext: "payment-options",
        component: AddOns,
        hideCurrentPill: false,
        useRoute: null,
        type: "checkout",
        approx: 2,
        showEgcPrice: false
    },
    paymentOptions: {
        key: "paymentOptions",
        name: "Payment options",
        route: "payment-options",
        gtmPageName: "payment_method",
        gtmPillClickLabel: "payment_method_checkout",
        defaultBack: "add-ons",
        defaultNext: "get-financing",
        component: CheckoutPaymentOptionRevamp,
        hideCurrentPill: false,
        useRoute: null,
        type: "checkout",
        approx: 0
    },
    summary: {
        key: "summary",
        name: "Checkout",
        route: "summary",
        gtmPageName: "Summary",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "order-confirmation",
        component: CheckoutSummaryAB,
        hideCurrentPill: false,
        useRoute: null,
        type: "checkout",
        approx: 0
    },
    orderConfirmed: {
        key: "orderConfirmed",
        name: "Order confirmed",
        route: "order-confirmation",
        gtmPageName: "confirmation",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "",
        component: OrderConfirmedInfo,
        hideCurrentPill: false,
        useRoute: null,
        type: "hidden",
        approx: 0
    },
    preApprovedLoan: {
        key: "preApprovedLoan",
        name: "Pre approved loan",
        route: "pre-approved-loan",
        gtmPageName: "pre_approved",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "summary",
        component: PreApprovedLoan,
        hideCurrentPill: false,
        useRoute: null,
        type: "pre-approved",
        approx: 0
    },
    // financing routes
    getFinancing: {
        key: "getFinancing",
        name: "get financing",
        route: "get-financing",
        gtmPageName: "Driva_value_prop",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "loan-details",
        component: GetFinancing,
        hideCurrentPill: false,
        useRoute: null,
        type: "get-financing",
        approx: 0
    },
    loanDetails: {
        key: "loanDetails",
        name: "Loan details",
        route: "loan-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "loan_details",
        defaultBack: "payment-options",
        defaultNext: "employment-details",
        showGreenTick: true,
        showPillNumber: true,
        component: FinanceLoanDetails,
        hideCurrentPill: false,
        useRoute: null,
        type: "financing",
        approx: 4
    },
    //loanType: {
    //    key: "loanType",
    //    name: "loan type",
    //    route: "loan-type",
    //    gtmPageName: "Driva_business_purpose",
    //    gtmPillClickLabel: "loan_type",
    //    defaultBack: "loan-details",
    //    defaultNext: "personal-details",
    //    component: FinanceLoanType,
    //    hideCurrentPill: false,
    //    useRoute: null,
    //    type: "financing",
    //    approx: 3
    //},
    employmentDetails: {
        key: "employmentDetails",
        name: "Employment details",
        route: "employment-details",
        gtmPageName: "Driva_employment_status",
        gtmPillClickLabel: "employment_details",
        defaultNext: "personal-details",
        defaultBack: "loan-details",
        showGreenTick: true,
        showPillNumber: true,
        component: FinanceEmploymentDetails,
        hideCurrentPill: false,
        useRoute: null,
        type: "financing",
        approx: 1
    },
    personalDetails: {
        key: "personalDetails",
        name: "Your details",
        route: "personal-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "driva_personal_details",
        defaultNext: "finance-first-loan-quote",
        defaultBack: "employment-details",
        showGreenTick: true,
        showPillNumber: true,
        component: FinancePersonalDetails,
        hideCurrentPill: false,
        useRoute: null,
        type: "financing",
        approx: 2
    },
    selectOffer: {
        key: "selectOffer",
        name: "Select offer",
        route: "select-offer",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: DrivaCustomizeWrapper,
        hideCurrentPill: true,
        useRoute: null,
        type: "financing",
        approx: 1
    },
    paymentSelection: {
        key: "paymentSelection",
        name: "Payment Summary Select",
        route: "select-payment",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: PaymentOverview,
        hideCurrentPill: false,
        useRoute: null,
        type: "splitPayment",
        approx: 1
    },
    bankTransferDetails: {
        key: "bankTransferDetails",
        name: "Bank Transfer",
        route: "bank-transfer-details",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: BSBBankTransfer,
        hideCurrentPill: true,
        useRoute: null,
        type: "checkout",
        approx: 1
    },
    selectDeposit: {
        key: "selectDeposit",
        name: "Select deposit",
        route: "select-deposit",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: ZeroDpReBookDeposit,
        hideCurrentPill: true,
        useRoute: null,
        type: "financing",
        approx: 1
    },
    //finance first non trade in
    ffOverview: {
        key: "ffOverview",
        name: "Basic details",
        route: "finance-first-basic-details",
        gtmPageName: "",
        gtmPillClickLabel: "basic_details",
        defaultBack: "",
        defaultNext: "select-offer",
        component: PreApprovedCheckoutBasic,
        hideCurrentPill: true,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 3,
        showEgcPrice: false,
        hideShippingPrice: true,
        eventLabelName: "Next_steps"
    },
    preApprovedTradeInIntro: {
        key: "preApprovedTradeInIntro",
        name: "Trade in",
        route: "finance-first-trade-in-intro",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "finance-first-loan-quote",
        defaultBack: "finance-first-basic-details",
        component: TradeInIntro,
        hideCurrentPill: false,
        useRoute: "trade-in",
        type: "finance-first-checkout",
        approx: 2,
        eventLabelName: "Trade_in",
        showGreenTick: true,
        showPillNumber: true

    },

    preApprovedTradeInValuation: {
        key: "preApprovedTradeInValuation",
        name: "Trade in",
        route: "finance-first-trade-in-valuation",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "delivery-details",
        component: TradeInValuation,
        hideCurrentPill: true,
        useRoute: "trade-in",
        type: "finance-first-checkout",
        approx: 2,
        eventLabelName: "Trade_in",
        showGreenTick: true,
        showPillNumber: true

    },
    loanQuote: {
        key: "loanQuote",
        name: "Loan quote",
        route: "finance-first-loan-quote",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "pre-aaproved-addons",
        defaultBack: "",
        component: DrivaCustomizeWrapper,
        hideCurrentPill: false,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 1,
        eventLabelName: "Loan_quotes",
        showGreenTick: true,
        showPillNumber: true

    },
    preApprovedAddOns: {
        key: "preApprovedAddOns",
        name: "Add ons",
        route: "finance-first-addons",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "loan-quote",
        component: PreApprovedAddOns,
        hideCurrentPill: false,
        useRoute: "trade-in",
        type: "finance-first-checkout",
        approx: 1,
        eventLabelName: "Add_ons",
        showGreenTick: true,
        showPillNumber: true

    },

    preApprovedSummary: {
        key: "preApprovedSummary",
        name: "Reserve car",
        route: "finance-first-summary",
        gtmPageName: "Summary",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "order-confirmation",
        component: CheckoutSummaryAB,
        hideCurrentPill: false,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 0,
        eventLabelName: "Reserve_car",
        showGreenTick: true,
        showPillNumber: true

    },

    //add-on screens

    preApprovedDelivery: {
        key: "preApprovedDelivery",
        name: "Delivery",
        route: "finance-first-delivery",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "finance-first-addons",
        component: PreApprovedDelivery,
        hideCurrentPill: true,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 1
    },
    preApprovedCarCare: {
        key: "preApprovedCarCare",
        name: "Car care",
        route: "finance-first-car-care",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "finance-first-addons",
        defaultBack: "finance-first-addons",
        component: AddOns,
        hideCurrentPill: true,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 1
    },

    preApprovedExtendedWarranty: {
        key: "preApprovedExtendedWarranty",
        name: "Extended warranty",
        route: "finance-first-extended-warranty",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "finance-first-addons",
        component: PreApprovedExtendedWarranty,
        hideCurrentPill: true,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 1
    },
    preApprovedPickUp: {
        key: "preApprovedPickUp",
        name: "Pick up",
        route: "finance-first-pick-up",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "finance-first-addons",
        component: PreApprovedPickUp,
        hideCurrentPill: true,
        useRoute: null,
        type: "finance-first-checkout",
        approx: 1
    }
};
export const checkoutRoutesArray = Object.values(checkoutRoutes);

export const routesToRedirectCheckout = checkoutRoutesArray
    .filter((routeData) => routeData.type === "checkout")
    .slice(1)
    .map((routeData) => routeData.route);

export const routesToRedirectFinance = checkoutRoutesArray
    .filter((routeData) => routeData.type === "financing")
    //.slice(1)
    .map((routeData) => routeData.route);

export const tradeInRoutes = checkoutRoutesArray.filter((routeData) => routeData.subType === "trade-in")
    .map((routeData) => routeData.route);

export const fastCheckout = checkoutRoutesArray.filter((routeData) => routeData.type === "ff-pre-approved")
    .map((routeData) => routeData.route);
