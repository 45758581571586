import Types from "./types";
import { B2cFinanceService, B2cHubspotService, ListingServiceAU, UserAuthService, geoLocationService } from "../../service";
import { HTTP_CODES, ISLOGGEDIN_COOKIE, LOCAL_STORAGE_KEYS } from "../../constants/app-constants";
import { proxyAuthState } from "../../service/userAuthApi";
import { AUTH_ADDRESS } from "../../constants/api-constants";
import { populateCheckoutPersonalData, populateEmailFromToken, resetCheckoutData } from "../au.mobile/checkout-info/actions";
import { populateEmailFromToken as populateEmailFromTokenDesktop } from "../au.desktop/checkout-info/actions";

import getMobileFromProfile from "../../utils/helpers/get-profile-mobile";
import { clearItem, getItem, setItem } from "../../utils/helpers/storage-crud";
import toTitleCase from "../../utils/helpers/to-title-case";
import { cleverTapLogin, updateCleverTapProfile } from "../../tracking";
import parseCookie from "../../utils/helpers/parse-cookie";
import saveCookie from "../../utils/helpers/save-cookie";
import { getDeviceType } from "../../utils/helpers/get-device-type";
import { resetPreApproval } from "../au.mobile/pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
import { LOGIN_TYPE } from "../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../constants/optimize-constants";
import jwtDecode from "jwt-decode";
import { setTestDriveVariant } from "../au.mobile/test-drive-v2/actions";
import { getPreApprovalLoanDetails } from "../au.desktop/pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
import { emitEventNames } from "../../constants/event-constants";

const setProfileName = (profileName) => ({
    type: Types.SET_PROFILE_NAME,
    profileName
});

const setMobile = (mobile) => ({
    type: Types.SET_MOBILE,
    mobile
});

const setLastVerifiedMobile = (lastVerifiedMobile) => ({
    type: Types.SET_LAST_VERIFIED_MOBILE,
    lastVerifiedMobile
});

const setProfileMobile = (profileMobile) => ({
    type: Types.SET_PROFILE_MOBILE,
    profileMobile
});

const setEmail = (email) => ({
    type: Types.SET_EMAIL,
    email
});

const setCheckAuth = (checkAuth) => ({
    type: Types.CHECK_AUTH,
    checkAuth
});

const setAffiliateSource = (affiliateSource) => ({
    type: Types.SET_AFFILIATE_SOURCE,
    affiliateSource
});

// // eslint-disable-next-line
// const getProfileDetailsSuccess = ({ email, name, mobilePhone, token }) => ({
//     type: Types.GET_PROFILE_DETAILS_SUCCESS,
//     email,
//     name,
//     token,
//     phoneNumber: mobilePhone
// });

const getProfileDetailsSuccess = (data) => {
    return {
        type: Types.GET_PROFILE_DETAILS_SUCCESS,
        data
    };
};

const getProfileDetailsFailure = (error) => ({
    type: Types.GET_PROFILE_DETAILS_FAILURE,
    error
});

const markUnauthorized = () => ({
    type: Types.MARK_UNAUTHORIZED
});

const getProfileName = (response) => {
    const { firstName, lastName, userName } = response || {};
    if (firstName) {
        return `${toTitleCase(firstName)} ${lastName || ""}`;
    }
    const userProfile = getItem(LOCAL_STORAGE_KEYS.userProfile);
    return (userProfile && userProfile.name) || userName;
};

// eslint-disable-next-line no-unused-vars
const postUserLoginEvent = (data = {}) => dispatch => {
    const {  accessToken } = data || {};
    const params = {
        token: accessToken,
        source: getDeviceType(),
        // userId: userName,
        // source: userProfiles[0] && userProfiles[0].addressType,
        hubspotutk: parseCookie("hubspotutk")
    };
    // saveCookie(ISLOGGEDIN_COOKIE, true);
    return new Promise((resolve, reject) => {
        B2cHubspotService.userLoginEvent(params, accessToken)
            .then((response) => {
                resolve(response);
                //response
            })
            .catch((error) => {
                reject(error);
                //error
            });
    });
};

const getZeroDpConfigInit = (isLoading) => {
    return {
        type: Types.GET_ZERO_DP_VARIANT_INIT,
        data: isLoading
    };
};

const getZeroDpConfigSuccess = (data) => {
    return {
        type: Types.GET_ZERO_DP_VARIANT_SUCCESS,
        data
    };
};

const getZeroDpConfigFailure = (error) => {
    return {
        type: Types.GET_ZERO_DP_VARIANT_FAILURE,
        error
    };
};

const setInitialZeroDpOptimizeVariant = (data) => {
    return {
        type: Types.SET_INITIAL_ZERO_DP_OPTIMIIZE_VARIANT,
        data
    };
};

const getZeroDpConfig = (token, payload = {}) => async (dispatch) => {
    try {
        dispatch(getZeroDpConfigInit(true));
        // web-show-zero-downpayment scaled to variant A
        const zeroDpVariant = ZERO_DP_VARIANTS.NON_ZERO_DP;
        const params = {zeroDpVariant, ...payload};
        if (token) {
            const response = await B2cFinanceService.getZeroDpConfig(token, params);
            dispatch(getZeroDpConfigSuccess(response.data));
            Promise.resolve(response.data);
        } else {
            dispatch(getZeroDpConfigSuccess({zeroDpVariant}));
            Promise.resolve({zeroDpVariant});
        }
    } catch (error) {
        dispatch(getZeroDpConfigFailure(error));
        Promise.reject(error);
    }
};

const setSecureToken = (secureToken, isGuestLogin) => ({
    type: Types.SET_SECURE_TOKEN,
    secureToken,
    isGuestLogin
});

const setIsCtSub = (value) => ({
    type: Types.SET_IS_CT_SUB,
    value
});

// eslint-disable-next-line max-statements
const getProfileDetails = (token, isGuestLogin, isNewUser) => async (dispatch, getState) => {
    const {
        user: {isCtSub = false}
    } = getState();
    try {
        const response = await UserAuthService.getProfileDetails(token);
        const profileMobile = getMobileFromProfile(response.data);
        dispatch(getProfileDetailsSuccess({...response.data, profileMobile, secureToken: token, isGuestLogin, isNewUser}));
        dispatch(populateCheckoutPersonalData({...response.data, profileMobile}));
        dispatch(setCheckAuth(false));
        const profileName = getProfileName(response.data);
        dispatch(setProfileName(profileName));
        if (isNewUser && !isCtSub) {
            cleverTapLogin({mobile: profileMobile, fullName: profileName, email: (response.data || {}).userName});
            dispatch(setIsCtSub(true));
        }
        if (!isNewUser) {
            dispatch(setIsCtSub(true));
        }
        // postUserLoginEvent({...response.data, profileMobile, secureToken: token});
        saveCookie(ISLOGGEDIN_COOKIE, true);
        Promise.resolve(response.data);
    } catch (error) {
        dispatch(getProfileDetailsFailure(error));
        dispatch(setCheckAuth(false));
        const guestLoginResponse = jwtDecode(token);
        if (guestLoginResponse.isGuestLogin) {
            // dispatch(populateCheckoutPersonalData(guestLoginResponse));
            // dispatch(populateCheckoutPersonalDataDesktop(guestLoginResponse));
            dispatch(populateEmailFromToken(guestLoginResponse));
            dispatch(populateEmailFromTokenDesktop(guestLoginResponse));
            dispatch(setSecureToken(token, guestLoginResponse.isGuestLogin));
            dispatch(setEmail(guestLoginResponse.email));
        }
        Promise.reject(error);
    }
};

// Currently not in use
const setProfileWithToken = (token) => dispatch => {

    dispatch(setSecureToken(token));
    dispatch(getProfileDetails(token));
};

// eslint-disable-next-line max-statements
const getRefreshTokenInfo = () => async (dispatch, getState) => {
    const { user: { affiliateSource: source, isAffiliate } } = getState();
    try {
        const headersObj = {
            ...(isAffiliate && {referrersource: source})
        };
        const response = await UserAuthService.getRefreshTokenInfo(headersObj);
        const { access_token: accessToken, isGuestLogin, isNewUser } = response.data || {};
        proxyAuthState.accessToken = accessToken;
        proxyAuthState.isLoggedIn = true;
        // saveCookie(ISLOGGEDIN_COOKIE, true);
        dispatch(getZeroDpConfig(accessToken));
        await dispatch(getProfileDetails(accessToken, isGuestLogin, isNewUser));
        dispatch(getPreApprovalLoanDetails({fetchListingDetails: true, accessToken}));
        return Promise.resolve(response.data);
    } catch (error) {
        proxyAuthState.accessToken = null;
        proxyAuthState.isLoggedIn = false;
        // dispatch(logoutFailure(error));
        dispatch(getZeroDpConfig(null));
        dispatch(getProfileDetailsFailure(error));
        return Promise.reject(error);
    }
};

const loginfromRefreshToken = (refreshToken = "") => async (dispatch) => {
    try {
        const headersObj = {
            ...(refreshToken && {refresh_token: refreshToken})
        };
        const response = await UserAuthService.getRefreshTokenInfoV2(headersObj);
        const { access_token: accessToken, isGuestLogin, isNewUser } = response.data || {};
        proxyAuthState.accessToken = accessToken;
        proxyAuthState.isLoggedIn = true;
        // saveCookie(ISLOGGEDIN_COOKIE, true);
        dispatch(getZeroDpConfig(accessToken));
        dispatch(getProfileDetails(accessToken, isGuestLogin, isNewUser));
        return Promise.resolve(response.data);
    } catch (error) {
        proxyAuthState.accessToken = null;
        proxyAuthState.isLoggedIn = false;
        // dispatch(logoutFailure(error));
        dispatch(getZeroDpConfig(null));
        dispatch(getProfileDetailsFailure(error));
        return Promise.reject(error);
    }
};

const updateProfileInit = () => ({
    type: Types.UPDATE_PROFILE
});

const updateProfileSuccess = (userData) => ({
    type: Types.UPDATE_PROFILE_SUCCESS,
    userData
});

const updateProfileFailure = (error) => ({
    type: Types.UPDATE_PROFILE_FAILURE,
    error
});

const updateProfile = (payload) => (dispatch, getState) => {
    dispatch(updateProfileInit());
    const { user: { secureToken: token, email, isNewUser} } = getState();
    return new Promise((resolve, reject) => {
        UserAuthService.updateProfile(payload, token).then(response => {
            const userData = {
                firstName: payload.firstName,
                lastName: payload.lastName,
                middleName: payload.middleName,
                mobile: payload.phone,
                fullName: `${payload.firstName} ${payload.middleName} ${payload.lastName}`.trim().replace("  ", " ")
            };
            const whatsappOptIn =  payload.whatsappOptIn || false;
            const clevertapData = {
                mobile: payload.phone,
                fullName: userData.fullName,
                email,
                whatsappOptIn
            };
            if (!isNewUser) {
                updateCleverTapProfile(clevertapData);
            } else {
                cleverTapLogin(clevertapData);
            }
            dispatch(updateProfileSuccess(userData));
            // dispatch(getProfileDetails(token));
            resolve(response);
        }).catch(error => {
            dispatch(updateProfileFailure(error));
            reject(error);
        });
    });
};

const setFirstLoad = () => ({
    type: Types.SET_FIRST_LOAD
});

const updateWebviewStatus = (isWebview) => ({
    type: Types.UPDATE_WEBVIEW_STATUS,
    isWebview
});

const setGAId = (gaId) => ({
    type: Types.SET_GA_ID,
    gaId
});

const setShowAllowTracking = (showAllowTracking) => ({
    type: Types.SET_SHOW_ALLOW_TRACKING,
    showAllowTracking
});

const setShowSpitPaymentModal = (showSplitPaymentModal) => ({
    type: Types.SET_SHOW_SPLIT_PAYMENT_MODAL,
    showSplitPaymentModal
});

const getTokenInfo = () => (dispatch, getState) => {
    const { user: { affiliateSource: source, isAffiliate } } = getState();
    const headersObj = {
        ...(isAffiliate && {referrersource: source})
    };
    return new Promise((resolve, reject) => {
        UserAuthService.getTokenInfo(headersObj)
            .then(response => {
                const { access_token: accessToken, isGuestLogin } = response.data || {};
                dispatch(getProfileDetails(accessToken, isGuestLogin));
                resolve(response.data);
            })
            .catch(error => {
                if (error.status === HTTP_CODES.UNAUTHORIZED) {
                    dispatch(markUnauthorized());
                }
                dispatch(setCheckAuth(false));
                reject(error);
            });
    });
};

const verifyOtp = (params) => (dispatch, getState) => {
    const { user: { affiliateSource: source, isAffiliate, gaId } } = getState();
    const headersObj = {
        ...(isAffiliate && {referrersource: source})
    };
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId || ""};
    return new Promise((resolve, reject) => {
        UserAuthService.verifyOTP(params, headersObj)
            .then(response => {
                const { access_token: accessToken, isGuestLogin, isNewUser } = response.data || {};
                dispatch(getProfileDetails(accessToken, isGuestLogin, isNewUser));
                dispatch(postUserLoginEvent({accessToken}));

                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const logoutSuccess = () => ({
    type: Types.LOGOUT_SUCCESS
});

const logoutFailure = () => ({
    type: Types.LOGOUT_FAILURE
});

const logout = (logoutSuccessCallback = () => {}) => (dispatch, getState) => {
    dispatch(updateProfileInit());
    const {
        user: {
            loginType
        }
    } = getState();
    return new Promise((resolve, reject) => {
        UserAuthService.logout().then(response => {
            dispatch(resetCheckoutData());
            if (loginType !== LOGIN_TYPE.PRE_APPROVAL) dispatch(resetPreApproval());
            clearItem(LOCAL_STORAGE_KEYS.userProfile);
            clearItem(LOCAL_STORAGE_KEYS.financeUniqueId);
            saveCookie(ISLOGGEDIN_COOKIE, false);
            dispatch(logoutSuccess());
            resolve(response);
            logoutSuccessCallback();
        }).catch(error => {
            dispatch(logoutFailure(error));
            reject(error);
        });
    });
};

const verifySignInRequest = (addressType) => ({
    type: Types.VERIFY_GOOGLE_SIGNIN_REQUEST,
    addressType
});
const verifySignInSuccess = (addressType) => ({
    type: Types.VERIFY_GOOGLE_SIGNIN_SUCCESS,
    addressType
});
const verifySignInFailure = (addressType) => ({
    type: Types.VERIFY_GOOGLE_SIGNIN_FAILURE,
    addressType
});

const verifySignIn = (params) => (dispatch, getState) => {
    dispatch(verifySignInRequest(params.addressType));
    const { user: { affiliateSource: source, isAffiliate, gaId = "" } } = getState();
    //test-drive-location scaled to VARIANT B
    // const showTestDriveV2Variant = getAbExpirementVariant(showTestDriveV2, EXPERIMENT_TYPE.VARIANT_B);
    const showTestDriveV2Variant = true;

    const headersObj = {
        ...(isAffiliate && {referrersource: source})
    };
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId || ""};
    return new Promise((resolve, reject) => {
        UserAuthService.verifySignIn(params, headersObj)
            .then(response => {
                dispatch(verifySignInSuccess(params.addressType));
                if (showTestDriveV2Variant) {
                    dispatch(setTestDriveVariant({email: params.username, testDriveVariant: showTestDriveV2Variant}));
                }
                const { access_token: accessToken, isGuestLogin, isNewUser } = response.data || {};
                dispatch(getZeroDpConfig(accessToken));
                dispatch(getProfileDetails(accessToken, isGuestLogin, isNewUser));
                dispatch(postUserLoginEvent({accessToken}));
                resolve(response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line no-debugger
                dispatch(verifySignInFailure(params.addressType));
                reject(error);
            });
    });
};

const sendOTP = (mobile, addressType = AUTH_ADDRESS.EMAIL) => () => {
    return new Promise((resolve, reject) => {
        UserAuthService.sendOTP(mobile, addressType)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const openLoginPopup = () => ({
    type: Types.OPEN_LOGIN_POPUP
});

const setLoginType = (data) => ({
    type: Types.SET_LOGIN_TYPE,
    data
});

const closeLoginPopup = () => ({
    type: Types.CLOSE_LOGIN_POPUP
});

const getSeoListInit = () => ({
    type: Types.SEO_HEADER_MENU
});

const getSeoListSuccess = (data, params) => ({
    type: Types.SEO_HEADER_MENU_SUCCESS,
    data,
    params
});

const getSeoListFailure = (data, params) => ({
    type: Types.SEO_HEADER_MENU_SUCCESS,
    data,
    params
});

const getSeoList = () => (dispatch) => {
    dispatch(getSeoListInit());
    return new Promise((resolve, reject) => {
        ListingServiceAU.getMenuSeoList()
            .then((response = {}) => {
                dispatch(getSeoListSuccess(response.data));
                resolve(response);
            })
            .catch((error) => {
                dispatch(getSeoListFailure(error));
                reject(error);
            });
    });
};

const setCurrentScreen = (data) => {
    let screenName = data;
    if (typeof window !== "undefined") {
        screenName = getItem("home_screen");
        if (!screenName) {
            setItem(screenName, "home_screen");
        }
    }
    return ({
        type: Types.SET_CURRENT_LOGO_URL,
        data: screenName
    });
};

const getGuestToken = (params) => (dispatch, getState) => {
    dispatch(verifySignInRequest(params.addressType));
    const { user: { affiliateSource: source, isAffiliate, gaId } } = getState();
    const headersObj = {
        ...(isAffiliate && {referrersource: source})
    };
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId || ""};
    return new Promise((resolve, reject) => {
        UserAuthService.getGuestTokenInfo(params, headersObj)
            .then(response => {
                const {access_token: token, isGuestLogin} = response.data;
                dispatch(verifySignInSuccess(params.addressType));
                dispatch(setSecureToken(token, isGuestLogin));
                dispatch(getZeroDpConfig(token));
                return resolve(response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line no-debugger
                dispatch(verifySignInFailure(params.addressType));
                reject(error);
            });
    });
};

const setProfileDetails = (data) => {
    return {
        type: Types.SET_PROFILE_DETAILS,
        data
    };
};

const clearUserDataforGuestLogin = (data = {}) => (dispatch) => {
    dispatch(populateCheckoutPersonalData(data || {}));
    // dispatch(setSecureToken());
};

const setHideSiteHeader = (data) => ({
    type: Types.HIDE_SITE_HEADER,
    data
});

const getUserLocationInit = () => {
    return {
        type: Types.GET_LOCATION_INIT
    };
};

const getUserLocationSuccess = (data) => {
    return {
        type: Types.GET_LOCATION_SUCCESS,
        data
    };
};

const getUserLocationFailure = (error) => {
    return {
        type: Types.GET_LOCATION_FAILURE,
        error
    };
};

const getUserGeoLocation = () => (dispatch) => {
    dispatch(getUserLocationInit());
    return new Promise((resolve, reject) => {
        geoLocationService.getGeoLocationData()
            .then(response => {
                dispatch(getUserLocationSuccess(response.data));
                return resolve(response.data);
            })
            .catch((error) => {
                dispatch(getUserLocationFailure(error));
                reject(error);
            });
    });
};

const closeClpNudge = () => ({
    type: Types.CLOSE_CLP_SALE_NUDGE
});

const closeClpNudgeHandler = () => (dispatch) => {
    dispatch(closeClpNudge());

};

const clpSaleAnimationSeen = () => ({
    type: Types.CLP_SALE_ANIMATION_SEEN
});

const clpSaleAnimationSeenHandler = () => (dispatch) => {
    dispatch(clpSaleAnimationSeen());

};

const setCurrentBrowser = (data) => ({
    type: Types.SET_CURRENT_BROWSER,
    data
});

const setIsSSR = (data) => ({
    type: Types.SET_IS_SSR,
    isSSR: data
});

const updateRecentlyViewedCars = (data = "") => {
    return ({
        type: Types.UPDATE_RECENTLY_VIEWED_CARS,
        data
    });
};

const setTradeInInterest = (interestedInTradein = null) => {
    return {
        type: Types.SET_TRADE_IN_INTEREST,
        interestedInTradein
    };
};

const updateTradeInInterest = (interestedInTradein) => (dispatch, getState) => {
    const {
        user: { secureToken }
    } = getState();

    return new Promise((resolve, reject) => {
        UserAuthService.updateTradeInInterest(secureToken, interestedInTradein)
            .then(response => {
                if (response.data) {
                    dispatch(setTradeInInterest(interestedInTradein));
                    resolve(response.data);
                }
            })
            .catch(error => {
                dispatch(setTradeInInterest(null));
                reject(error);
            });
    });
};

const getTradeInInterest = () => (dispatch, getState) => {
    const {
        user: { secureToken }
    } = getState();

    return new Promise((resolve, reject) => {
        UserAuthService.getTradeInInterest(secureToken)
            .then(response => {
                if (response?.data) {
                    dispatch(setTradeInInterest(response?.data?.interestedInTradein));
                } else {
                    dispatch(setTradeInInterest(null));
                }
                resolve(response?.data);
            })
            .catch(error => {
                dispatch(setTradeInInterest(null));
                reject(error);

            });
    });
};
const updateIsFirstUserSession = (data) => ({
    type: Types.UPDATE_IS_FIRST_USER_SESSION,
    data
});

export {
    setProfileName,
    setMobile,
    setProfileMobile,
    setEmail,
    setProfileWithToken,
    setCheckAuth,
    setFirstLoad,
    updateWebviewStatus,
    setGAId,
    updateProfile,
    setLastVerifiedMobile,
    getTokenInfo,
    verifyOtp,
    logout,
    logoutSuccess,
    getRefreshTokenInfo,
    loginfromRefreshToken,
    verifySignIn,
    sendOTP,
    getProfileDetails,
    setSecureToken,
    openLoginPopup,
    closeLoginPopup,
    setShowAllowTracking,
    setShowSpitPaymentModal,
    getSeoList,
    setAffiliateSource,
    setLoginType,
    setCurrentScreen,
    getZeroDpConfig,
    getZeroDpConfigSuccess,
    getZeroDpConfigFailure,
    setInitialZeroDpOptimizeVariant,
    getGuestToken,
    setProfileDetails,
    clearUserDataforGuestLogin,
    setHideSiteHeader,
    getUserGeoLocation,
    closeClpNudgeHandler,
    clpSaleAnimationSeenHandler,
    setCurrentBrowser,
    setIsSSR,
    updateRecentlyViewedCars,
    updateTradeInInterest,
    getTradeInInterest,
    updateIsFirstUserSession
};
