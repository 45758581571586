import { createReducer } from "reduxsauce";
import { DEFAULT_FINANCE_TYPE } from "../../../config/finance-config";
import Types from "./types";

export const INITIAL_STATE = {
    showInHouseFinance: {
        data: DEFAULT_FINANCE_TYPE.showInHouseVariant
    },
    showWarrantyPageShift: {
        data: null
    },
    showNonPreApprovedPayOptions: {
        data: null
    },
    showPremiumFeatures: {
        data: null
    },
    showUserDeliveryTimeline: {
        data: null
    },
    financeFirstCheckoutRevamp: {
        data: null
    },
    c2bMechanicalCondition: {
        data: "VARIANT_A"
    },
    webMobilePreBiAssistance: {
        data: null
    },
    statsigConfig: {
        initialized: false
    },
    c2bSellLandingMobileNumber: {
        data: null
    },
    msiteClpLoanCalculator: {
        data: null
    },
    servicingBundleRevamp: {
        data: null
    },
    webPriceDropMsite: {
        data: null
    },
    webPriceDropDesktop: {
        data: null
    }
};

export const setExperiment = (state = INITIAL_STATE, {
    experimentType, experimentData, flattened = false
}) => {
    return {
        ...state,
        ...(flattened ? { [experimentType]: experimentData } :
            {
                [experimentType]: {
                    data: experimentData
                }
            })
    };
};

export const setAllABExperiment = (state = INITIAL_STATE, { variants }) => {
    return {
        ...state,
        ...variants
    };
};

export const setStatsigInitialization = (state = INITIAL_STATE, { statsigInitialized }) => {
    return {
        ...state,
        statsigConfig: {
            initialized: statsigInitialized
        }
    };
};

export const allClearExperiment = () => {
    return {};
};

export const clearExperiment = ({ updatedExperiments }) => {
    return {
        updatedExperiments
    };
};

export const HANDLERS = {
    [Types.SET_EXPERIMENT]: setExperiment,
    [Types.SET_ALL_EXPERIMENT]: setAllABExperiment,
    [Types.SET_STATSIG_INITIALIZATION]: setStatsigInitialization,
    [Types.ALL_CLEAR_EXPERIMENT]: allClearExperiment,
    [Types.CLEAR_EXPERIMENT]: clearExperiment
};

export default createReducer(INITIAL_STATE, HANDLERS);
