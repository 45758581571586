import { X_VEHICLE_TYPE } from "../constants/api-constants";
import { getDeviceType } from "../utils/helpers/get-device-type";
// eslint-disable-next-line max-statements
export default (api) => {
    const fetchConfigDetails = ({payload, source}) => {
        return api.post(`/v1/public/config/fetch`, payload, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                source: source || getDeviceType()
            }
        });
    };

    const getConfigDetailsWithType = ({type, source}) => {
        return api.get(`/v1/public/config?configType=${type}${source ? `&key=${source}` : ""}`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const fetchDrivaConfigDetails = ({type, key}) => {
        return api.get(`/v1/public/config?configType=${type}&key=${key}`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    return {
        fetchConfigDetails,
        fetchDrivaConfigDetails,
        getConfigDetailsWithType
    };
};
