import React from "react";
import PropTypes from "prop-types";
// import FormLayoutV1 from "../form-layout-v1";
import styles from "./styles.css";
import DynamicForm from "../dynamic-form";
import FormLayoutV1 from "../form-layout-v1";
import InputText from "../../shared/input-text";
import Search from "../../shared/search";
import { verifyDrivingLicenceConfig } from "../post-payment-journey/verify-user-licence/verifyLicenceFormConfig";
import FormFooter from "../form-footer";
import { VERIFY_LICENCE_SCREEN_TYPES } from "../post-payment-journey/verify-user-licence/constants";
import VerifyLicenceUploadFileContainer from "../verify-licence-upload-file-container";
import { SIGN_CONTRACT_STATUS } from "../../../constants/checkout-constants";
import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import { tasksRoutes } from "../../../constants/au.mobile/tasks-routes";

const getFormConfig = (formConfig, formData) => {
    const config = formConfig && formConfig({
        components: {
            inputText: InputText,
            uploadFile: VerifyLicenceUploadFileContainer,
            select: Search
        },
        formData
    });
    return config;
};

const VerifyDrivingLicence = ({
    saveDrivingLicenceDetailsConnect,
    setLicenceUploadScreenTypeConnect,
    signContractData = [],
    history,
    content
}) => {
    const { documentStatus } = Array.isArray(signContractData) && signContractData[0] || {};
    const formData = {};
    const config = getFormConfig(verifyDrivingLicenceConfig, {});
    const progressSteps = config.stages;

    const handleNext = async ({ values }) => {
        const { drivingLicenseDetails } = values;
        const { licenceState: state, licenceType: type, licenceNumber: number } = drivingLicenseDetails;
        const payload = {
            state,
            type,
            number
        };
        await saveDrivingLicenceDetailsConnect(payload);
        if (documentStatus === SIGN_CONTRACT_STATUS.SIGNED) {
            const { relativeURL: viewTaskUrl } = getMyBookingCarDetailsURL(
                content.appointmentId,
                tasksRoutes.viewTask.route
            );

            history.push(viewTaskUrl);
        } else {
            setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION);
        }
    };

    const handleBack = () => {
        setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.INTRO);
    };

    return (
        <div styleName={"styles.outer"}>
            <FormLayoutV1
                outerContainer={"container"}
                formConfig={config}
                handleOnNext={handleNext}
                handleOnBack={handleBack}
                formData={formData}
                steps={progressSteps}
                middleComponent={DynamicForm}
                middleComponentProps={
                    {
                        middleComponentStyles: styles.middleComponentStyles
                    }
                }
            >
                {({ onClickBack, onClickNext, isNextLoading, currentFormData }) => (
                    <div
                        styleName={"styles.footerStyles"}>
                        <FormFooter
                            onClickBack={onClickBack}
                            onClickNext={onClickNext}
                            isNextLoading={isNextLoading}
                            currentFormData={currentFormData}
                        />
                    </div>
                )}
            </FormLayoutV1>
        </div>);
};

VerifyDrivingLicence.propTypes = {
    setLicenceUploadScreenTypeConnect: PropTypes.func,
    getLicenceUploadStatusByOrderIdConnect: PropTypes.func,
    saveDrivingLicenceDetailsConnect: PropTypes.func,
    signContractData: PropTypes.array,
    history: PropTypes.object,
    content: PropTypes.object
};

export default VerifyDrivingLicence;
