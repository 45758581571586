import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VerifyLicenceUploadFileContainer from "./component";
import { downloadLicenceImage, getLicenceUploadStatusByOrderId, uploadDrivingLicence } from "../checkout-info/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getLicenceUploadStatusByOrderIdConnect: getLicenceUploadStatusByOrderId,
    uploadDrivingLicenceConnect: uploadDrivingLicence,
    downloadLicenceImageConnect: downloadLicenceImage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyLicenceUploadFileContainer);
