import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VerifyUserLicencey from "./component";
import { getPaymentSummary } from "../../my-bookings/actions";
import { setLicenceUploadScreenType } from "../../checkout-info/actions";

const mapStateToProps = (
    {
        checkout: {
            order,
            signContractData,
            drivingLicenceData
        }

    }
) => ({
    order,
    signContractData,
    drivingLicenceData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPaymentSummaryConnect: getPaymentSummary,
    setLicenceUploadScreenTypeConnect: setLicenceUploadScreenType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUserLicencey);
