import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTradeInState, updateTradeIn, getTradeInDetails, createTradeIn, setUserUpdating, setMaxStep, getTradeVehicleDetails,  handleRejectModal, getSellOrderDetails } from "../trade-in-wrapper/actions";
import C2bLandingAtfForm from "./component";
import withGuestLogin from "../guest-login";
import withLogin from "../../../../components/au.desktop/with-login";
import { withRouter } from "react-router-dom";
import { saveTradeInRegoandVin } from "../c2b-no-kbb-layout/actions";
import { updateFinanceDetail, updateTradeIn as updateCheckoutTradeIn } from "../../../../components/au.desktop/checkout-info/actions";
import { getTradeInToggleVisible } from "../../../../components/au.desktop/trade-in-your-car-wrapper/actions";

const mapStateToProps = ({
    user: {isGuestLogin, isLoggedIn, gaId},
    abExperiment: {
        c2bSellLandingMobileNumber
    },
    checkout: {
        order: {
            paymentOption,
            orderId
        }
    },
    auc2bTradein: {
        tradeInData,
        tradeInStates,
        rejectModal,
        seoData,
        isFetchingConfig
    }}) => ({
    tradeInData,
    tradeInStates,
    rejectModal,
    seoData,
    isFetchingConfig,
    isLoggedIn,
    isGuestLogin,
    gaId,
    c2bSellLandingMobileNumber,
    paymentOption,
    orderId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTradeInStateConnect: getTradeInState,
    updateTradeInConnect: updateTradeIn,
    getTradeInDetailsConnect: getTradeInDetails,
    createTradeInConnect: createTradeIn,
    setUserUpdatingConnect: setUserUpdating,
    setMaxStepConnect: setMaxStep,
    getTradeVehicleDetailsConnect: getTradeVehicleDetails,
    handleRejectModalConnect: handleRejectModal,
    getSellOrderDetailsConnect: getSellOrderDetails,
    saveTradeInRegoandVinConnect: saveTradeInRegoandVin,
    updateFinanceDetailConnect: updateFinanceDetail,
    getTradeInToggleVisibleConnect: getTradeInToggleVisible,
    updateCheckoutTradeInConnect: updateCheckoutTradeIn
}, dispatch);

export default withRouter(withGuestLogin(withLogin(connect(mapStateToProps, mapDispatchToProps)(C2bLandingAtfForm))));
