import React from "react";
import styles from "./styles.css";
// import Modal from "../../shared/modal";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});
import animationData from "./dynamicform.json";
import PropTypes from "prop-types";
import { FINANCE_BROKER_NUMBER } from "../../../constants/checkout-constants";

const defaultLottieOptions = {
    loop: false,
    autoplay: true,
    speed: 4000,
    animationData
};
const DynamicFormLoader = ({
    title = "",
    description = ""
}) => {
    return (

        <div styleName={"styles.loaderOuter"}>
            <Lottie options={defaultLottieOptions} />
            <h2>{title}</h2>
            <p>{description}</p>
            <div styleName={"styles.bottomContainer"}>
                <h5 styleName={"styles.bottomText"}>If you have doubts, call us:<span><a href={`tel:${FINANCE_BROKER_NUMBER}`}>{FINANCE_BROKER_NUMBER}</a></span></h5>
            </div>
        </div>

    );
};

DynamicFormLoader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default DynamicFormLoader;
