export const GA_EVENT_NAMES = {
    // make exposure dynamic
    "experiment": {
        event: "experiment",
        eventCategory: "assistance",
        eventAction: "variant_assigned"
    },
    "assistance_nudge_seen_text": {
        event: "assistance_nudge_seen",
        eventCategory: "assistance",
        eventAction: "text"
    },
    "assistance_nudge_seen_page_source": {
        event: "assistance_nudge_seen",
        eventCategory: "assistance",
        eventAction: "page_source"
    },
    "assistance_nudge_clicked_text": {
        event: "assistance_nudge_clicked",
        eventCategory: "assistance",
        eventAction: "text"
    },
    "assistance_nudge_clicked_page_source": {
        event: "assistance_nudge_clicked",
        eventCategory: "assistance",
        eventAction: "page_source"
    },
    "assistance_bubble_clicked": {
        event: "assistance_bubble_clicked",
        eventCategory: "assistance",
        eventAction: "page_source"
    },
    "assistance_option_selected_type": {
        event: "assistance_option_selected",
        eventCategory: "assistance",
        eventAction: "option_selected"
    },
    "assistance_option_selected_business": {
        event: "assistance_option_selected",
        eventCategory: "assistance",
        eventAction: "business_selected"
    },
    "assistance_console_screen": {
        event: "assistance_console_screen",
        eventCategory: "assistance",
        eventAction: "active_hours"
    }
};
