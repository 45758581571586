
import qs from "querystring";
import { AUTH_PRODUCT_TYPE, X_VEHICLE_TYPE } from "../constants/api-constants";

// eslint-disable-next-line max-statements
export default (api) => {
    const getProfileDetails = (token) => {
        return api.get(`/user/me`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }

        });
    };

    const getTokenInfo = (headers, product = AUTH_PRODUCT_TYPE) => {
        return api.get(`/oauth/${product}/token`, (!!Object.keys(headers) && { headers }));
    };

    const getRefreshTokenInfo = (headers, product = AUTH_PRODUCT_TYPE) => {
        return api.get(`/oauth/${product}/token/refresh`, (!!Object.keys(headers) && { headers }));
    };

    const getRefreshTokenInfoV2 = (headers, product = AUTH_PRODUCT_TYPE) => {
        return api.get(`/oauth/${product}/token/refresh/v2`, (!!Object.keys(headers) && { headers }));
    };

    const sendOTP = (address, addressType, product = AUTH_PRODUCT_TYPE) => {
        return api.get(`/oauth/${product}/otp/${address}`, {params: {addressType}});
    };

    const verifyOTP = (params, customHeaders = {}, product = AUTH_PRODUCT_TYPE) => {
        return api.post(`/oauth/${product}/token`, qs.stringify(params), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                ...customHeaders
            }
        });
    };

    const verifyMockSignIn = (params, customHeaders = {}, product = AUTH_PRODUCT_TYPE) => {
        return api.post(`/oauth/${product}/v2/token`, qs.stringify(params), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                ...customHeaders
            }
        });
    };

    const verifySignIn = (params, customHeaders = {}, product = AUTH_PRODUCT_TYPE) => {
        return api.post(`/oauth/${product}/token`, qs.stringify(params), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                ...customHeaders
            }
        });
    };

    const updateProfile = (payload, token) => {
        return api.put(`/user/me?product=${AUTH_PRODUCT_TYPE}`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const login = (params) => {
        return api.get(`/pp/auth/login`, { params });
    };

    const updateKycDocument = (token, documentType, attachments) => {
        return api.post("/pp/user/kyc", {
            documentType,
            attachments: {
                BACK: attachments.back,
                FRONT: attachments.front
            }
        }, {
            headers: {
                Authorization: `${token}`
            }}
        );
    };
    const updateDocument = (token, params) => {
        return api.put("/pp/document", params, {
            headers: {
                Authorization: `${token}`,
                "content-type": "multipart/form-data;"
            }
        });
    };

    const getUploadedDocument = (token, documentType) => {
        return api.get("/pp/document", documentType, {headers: {Authorization: `${token}`}});
    };

    const getDocument = (token, documentType) => {
        return api.get(`/pp/user/kyc`, { params: { documentType }, headers: {
            Authorization: `${token}`
        } });
    };

    const uploadFile = (url, data) => {
        return api.post(url, data);
    };

    const updateAffilateSource = (token, userId, source) => {
        return api.get(`/v1/acquisition`, { params: { userId, source}, headers: {
            Authorization: `Bearer ${token}`
        } });
    };

    const logout = (params, product = AUTH_PRODUCT_TYPE) => {
        return api.get(`/oauth/${product}/logout`);
    };

    const getGuestTokenInfo = (params, customHeaders = {}, product = AUTH_PRODUCT_TYPE) => {
        return api.post(`/oauth/${product}/guest/token`, qs.stringify(params), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                ...customHeaders
            }
        });
    };

    const updateTradeInInterest = (token, interestedInTradein) => {
        return api.put(`/v2/user/meta`, { interestedInTradein }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getTradeInInterest = (token) => {
        return api.get(`/v2/user/meta`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    return {
        getProfileDetails,
        getTokenInfo,
        sendOTP,
        verifyOTP,
        updateProfile,
        login,
        updateKycDocument,
        updateDocument,
        getUploadedDocument,
        getDocument,
        uploadFile,
        logout,
        getRefreshTokenInfo,
        getRefreshTokenInfoV2,
        verifySignIn,
        updateAffilateSource,
        verifyMockSignIn,
        getGuestTokenInfo,
        updateTradeInInterest,
        getTradeInInterest
    };
};
