export const EXPERIMENT_NAME = {
    ab_test: "ab_test",
    c2bSellLandingMobileNumber: "c2b_sell_landing_mobile_number",
    financeFirstCheckoutRevamp: "finance_first_checkout_revamp",
    showClpLoanCalculator: "msite_clp_loan_calculator",
    web_mobile_pre_bi_assistance: "web_mobile_pre_bi_assistance",
    servicing_bundle_revamp: "servicing_bundle_revamp",
    web_price_drop_msite: "web_price_drop_msite",
    web_price_drop_desktop: "web_price_drop_desktop"
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B"
};

export const SERVICING_VARIANT_MAPPING = {
    CONTROL: "OLD",
    VARIANT: "TESTING"
};
