/* eslint-disable complexity */

import { CURRENCY_ABBREVIATION } from "../../constants/app-constants";
import { FILTER_ENTITIES } from "../../utils/filters-v2/constants";

const basicParser = filter => filter.map(fv => fv.displayText).join("|");
const rangeParser = (filter = []) => (filter && filter[0]) ? [filter[0].min, filter[0].max].join("-") : undefined;
const budgetParser = (filter = []) => (filter && filter[0]) ? {min: filter[0].min, max: filter[0].max} : {};

const parserMap = {
    [FILTER_ENTITIES.PRICE]: budgetParser,
    [FILTER_ENTITIES.BODY_TYPE]: basicParser,
    [FILTER_ENTITIES.ODOMETER_READING]: rangeParser,
    [FILTER_ENTITIES.YEAR]: rangeParser,
    [FILTER_ENTITIES.FUEL_TYPE]: basicParser,
    [FILTER_ENTITIES.TRANSMISSION]: basicParser,
    [FILTER_ENTITIES.MAKE]: basicParser
};

export const parseFilterToDimensionGA4 = (filters, applied, selectedSort) => {
    const appliedFilters = Object.keys(filters || {})
        .filter(filter => applied.filter(f => f.filterKey === filter).length && parserMap[filter])
        .reduce((pv, cv) => {
            return {
                ...pv,
                ...{ [cv]: parserMap[cv](applied.filter(f => f.filterKey === cv)) }
            };
        }, {});

    return {
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension10: appliedFilters[FILTER_ENTITIES.PRICE].min }),
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension11: appliedFilters[FILTER_ENTITIES.PRICE].max }),
        ...(appliedFilters[FILTER_ENTITIES.MAKE] && { dimension12: appliedFilters[FILTER_ENTITIES.MAKE] }),
        ...(appliedFilters[FILTER_ENTITIES.ODOMETER_READING] && { dimension13: appliedFilters[FILTER_ENTITIES.ODOMETER_READING] }),
        ...(appliedFilters[FILTER_ENTITIES.YEAR] && { dimension14: appliedFilters[FILTER_ENTITIES.YEAR] }),
        ...(appliedFilters[FILTER_ENTITIES.FUEL_TYPE] && { dimension15: appliedFilters[FILTER_ENTITIES.FUEL_TYPE] }),
        ...(appliedFilters[FILTER_ENTITIES.TRANSMISSION] && { dimension16: appliedFilters[FILTER_ENTITIES.TRANSMISSION] }),
        ...(appliedFilters[FILTER_ENTITIES.BODY_TYPE] && { dimension18: appliedFilters[FILTER_ENTITIES.BODY_TYPE]}),
        ...(selectedSort?.key && { dimension17: selectedSort?.key})
    };
};

const carData = (carDetailsData, {
    listingType,
    position,
    bookingAmount = 0,
    excludedKeys = [],
    filterDimensions = {}
}) => {
    const {
        appointmentId,
        carId,
        price,
        lastPrice,
        listingPrice,
        make,
        body,
        bodyType,
        city,
        views,
        odometerReading,
        kilometerDriven,
        discount,
        year,
        model,
        transmissionType,
        fuelType,
        isPriceDrop
    } = carDetailsData || {};
    const data = {
        item_name: `${year} ${make} ${model}`,
        item_id: appointmentId || carId,
        price: price || lastPrice || listingPrice,
        discount,
        currency: CURRENCY_ABBREVIATION.AU,
        item_brand: make,
        item_category: bodyType || body,
        item_variant: fuelType,
        item_list_id: listingType,
        quantity: 1,
        ...(position && { index: position }),
        dimension4: city,
        ...(views && {dimension5: views}),
        dimension6: odometerReading || kilometerDriven,
        dimension7: year,
        dimension8: transmissionType,
        dimension9: model,
        dimension19: bookingAmount,
        price_drop: isPriceDrop ? "Yes" : "No",
        ...filterDimensions
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

export default (payload, properties) => ({
    ecommerce: {
        items: [carData(payload, properties)]
    }
});
