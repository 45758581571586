import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import LazyImage from "../../shared/lazy-image";
import CARS24Logo from "./images/cars24_logo.png";
import Tick from "./images/tick.png";
import Invalid from "./images/invalid.png";
import CrossIcon from "./images/no-icon.svg";
import Modal from "../../shared/modal";
import LargeTick from "./images/large-tick.svg";
import PropTypes from "prop-types";
import closeImage from "./images/close.svg";
import infoIcon from "./images/info.svg";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { PLATINUM_COVER_GA_EVENTS } from "../cars24-cover-revamp/tracking";

const BENEFITS_CONFIG = {
    Pricing: {
        heading: "Price/basic service",
        cars24: "< $141+",
        mechanics: "$300+",
        manufacturer: "$220"
    },
    Value: {
        heading: "Locked in price",
        cars24: "Upto 3 years",
        mechanics: "No",
        manufacturer: "No",
        cross: true
    },
    Assurance: {
        heading: "Tyre credit",
        cars24: "$100 (on warranty extension)",
        mechanics: "No",
        manufacturer: "No",
        cross: true
    }
};

const SERVICE_BENEFITS_CONFIG = {
    Pricing: {
        "heading": "Parts covered",
        "cars24": "Manufacturer level",
        "manufacturer": "Limited items"
    },
    Value: {
        "heading": "Number of claims",
        "cars24": "Unlimited",
        "manufacturer": "Unlimited"
    },
    Assurance: {
        "heading": "Claim limit",
        "cars24": "Upto value of car",
        "manufacturer": "5000 per claim"
    },
    Finance: {
        "heading": "Claims management ",
        "cars24": "CARS24 staff",
        "manufacturer": "Third parties"
    },
    Support: {
        "heading": "Support",
        "cars24": "World class team at your service",
        "manufacturer": "Varies or limited support"
    }
};

const Cars24PlatinumCoverTheBestModal = ({ onClose, isOpen }) => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (isOpen) {
            trackDesktopCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.WHAT_IS_COVERED_MODAL_OPENED);
        }
    }, [isOpen]);

    return (
        <Modal close={onClose} isOpen={isOpen}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.topSection"}>
                    <div styleName={"styles.headerWrap"}>
                        <p styleName={"styles.title"}>Why is CARS24 platinum cover the best?</p>
                        <img src={closeImage} onClick={onClose} />
                    </div>
                    <div styleName={"styles.tabWrapper"}>
                        <div
                            onClick={() => setActiveTab(0)}
                            styleName={`styles.tab ${activeTab === 0 ? "styles.tabActive" : ""}`}
                        >
                            <p styleName={"styles.tabName"}>Servicing</p>
                        </div>
                        <div
                            onClick={() => setActiveTab(1)}
                            styleName={`styles.tab ${activeTab === 1 ? "styles.tabActive" : ""}`}
                        >
                            <p styleName={"styles.tabName"}>Warranty</p>
                        </div>
                    </div>
                </div>
                {activeTab === 0 ? (
                    <div>
                        <div styleName="styles.warrantyTable">
                            <table>
                                <tr>
                                    <th>Feature</th>
                                    <th>
                                        <LazyImage src={CARS24Logo} />
                                    </th>
                                    <th>Manufacturer</th>
                                    <th>Other mechanics</th>
                                </tr>
                                {Object.keys(BENEFITS_CONFIG).map((item) => {
                                    const { heading, cars24, mechanics, manufacturer, cross } = BENEFITS_CONFIG[item];
                                    return (
                                        <tr>
                                            <td>{heading}</td>
                                            <td>
                                                <div>
                                                    <img src={Tick} alt="available" height="16px" width="16px" />
                                                    {cars24}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {cross ? (
                                                        <img src={CrossIcon} alt="invalid" height="16px" width="16px" />
                                                    ) : (
                                                        <img src={Invalid} alt="invalid" height="16px" width="16px" />
                                                    )}
                                                    {manufacturer}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {cross ? (
                                                        <img src={CrossIcon} alt="invalid" height="16px" width="16px" />
                                                    ) : (
                                                        <img src={Invalid} alt="invalid" height="16px" width="16px" />
                                                    )}
                                                    {mechanics}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                        <div styleName={"styles.serviceIncludeWrapper"}>
                            <p styleName={"styles.serviceInclude"}>Services include :</p>
                            <div styleName={"styles.listContainer"}>
                                <p styleName={"styles.list"}>
                                    <img src={LargeTick} />
                                    Full vehicle inspection
                                </p>
                                <p styleName={"styles.list"}>
                                    <img src={LargeTick} />
                                    Test battery condition
                                </p>
                                <p styleName={"styles.list"}>
                                    <img src={LargeTick} />
                                    Top up engine oil
                                </p>
                                <p styleName={"styles.list"}>
                                    <img src={LargeTick} />
                                    Top up windscreen washer fluid
                                </p>
                                <p styleName={"styles.list"}>
                                    <img src={LargeTick} />
                                    Engine oil filter replacement
                                </p>
                                <p styleName={"styles.list"}>
                                    <img src={LargeTick} />
                                    Road test vehicle
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div styleName="styles.serviceTableWrapper">
                        <table>
                            <tr>
                                <th>Feature</th>
                                <th>
                                    <LazyImage src={CARS24Logo} />
                                </th>
                                <th>Avg. cover</th>
                            </tr>
                            {Object.keys(SERVICE_BENEFITS_CONFIG).map((item) => {
                                const { heading, cars24, manufacturer } = SERVICE_BENEFITS_CONFIG[item];
                                return (
                                    <tr>
                                        <td>{heading}</td>
                                        <td>
                                            <div>
                                                <img src={Tick} alt="available" height="16px" width="16px" />
                                                {cars24}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <img src={Invalid} alt="invalid" height="16px" width="16px" />
                                                {manufacturer}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                )}

                <div styleName="styles.serviceTableWrapper">
                    <p styleName={"styles.disclaimer"}>
                        <img src={infoIcon} />
                        <span>Free Roadside Assistance (RSA)&nbsp;</span> with CARS24 Platinum cover purchase
                    </p>
                </div>
            </div>
        </Modal>
    );
};

Cars24PlatinumCoverTheBestModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.func
};

export default Cars24PlatinumCoverTheBestModal;
