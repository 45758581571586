/* eslint-disable no-undef */
import React, { useState } from "react";
import styles from "./styles.css";
import UPLOADICON from "./images/upload-icon.svg";
import ErrorText from "../../shared/error-text";
import { NUMBER } from "../../../constants/app-constants";
import PropTypes from "prop-types";

const UploadFlieCard = ({
    fileSize,
    fileExtensionsRegex,
    fileExtenionText,
    onUpload = () => {}
}) => {
    const [fileError, setFileError] = useState("");

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const { name: fileName, size } = file || {};
        const val = (fileName || "").toLowerCase();
        const regex = fileExtensionsRegex;
        let error = "";
        if (fileExtensionsRegex && !(regex.test(val))) {
            error = "Please upload a file format that is supported.";
        }
        const fileSizeinMB = size / NUMBER.TEN_LAKH;
        if (fileSize && (fileSizeinMB > fileSize)) {
            error = `${error} Please upload a file < 5MB in size`;
        }
        if (error) {
            setFileError(error);
        } else {
            onUpload(file);
        }
    };
    return (
        <React.Fragment>
            <div styleName={"styles.fileWrapper"}>
                <div styleName={"styles.uploadWrapper"}>
                    <img src={UPLOADICON} />
                </div>
                <h2>Browse file or drag here</h2>
                <h3>Browse</h3>
                {fileSize && fileExtenionText && <p>{fileExtenionText}, <span> Max size: {fileSize}MB</span></p>}
                <ErrorText errorMsg={fileError} isError={!!fileError} />
                <input type="file" name="myfile" onChange={handleFileUpload} />
            </div>
        </React.Fragment>
    );
};

UploadFlieCard.propTypes = {
    fileSize: PropTypes.string,
    restProps: PropTypes.object,
    fileExtensionsRegex: PropTypes.string,
    financeUploadDocumentConnect: PropTypes.func,
    onUpload: PropTypes.func,
    fileExtenionText: PropTypes.string
};

export default UploadFlieCard;
