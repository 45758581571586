/* eslint-disable complexity */

import { CURRENCY } from "../../constants/app-constants";
import { FILTER_ENTITIES } from "../../utils/filters-v2/constants";
import getCarInfo from "../../utils/helpers/get-car-info";

const basicParser = filter => filter.map(fv => fv.displayText).join("|");
// const basicParser = (filter = {}) => filter.displayText;
const rangeParser = (filter = []) => (filter && filter[0]) ? [filter[0].min, filter[0].max].join("-") : undefined;
const budgetParser = (filter = []) => (filter && filter[0]) ? {min: filter[0].min, max: filter[0].max} : {};

const parserMap = {
    [FILTER_ENTITIES.PRICE]: budgetParser,
    [FILTER_ENTITIES.BODY_TYPE]: basicParser,
    [FILTER_ENTITIES.ODOMETER_READING]: rangeParser,
    [FILTER_ENTITIES.YEAR]: rangeParser,
    [FILTER_ENTITIES.FUEL_TYPE]: basicParser,
    [FILTER_ENTITIES.TRANSMISSION]: basicParser,
    [FILTER_ENTITIES.MAKE]: basicParser
};

export const parseFilterToDimension = (filters, applied, selectedSort) => {
    const appliedFilters = Object.keys(filters || {})
        .filter(filter => applied.filter(f => f.filterKey === filter).length && parserMap[filter])
        .reduce((pv, cv) => {
            return {
                ...pv,
                ...{ [cv]: parserMap[cv](applied.filter(f => f.filterKey === cv)) }
            };
        }, {});

    return {
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension10: appliedFilters[FILTER_ENTITIES.PRICE].min }),
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { dimension11: appliedFilters[FILTER_ENTITIES.PRICE].max }),
        ...(appliedFilters[FILTER_ENTITIES.MAKE] && { dimension12: appliedFilters[FILTER_ENTITIES.MAKE] }),
        ...(appliedFilters[FILTER_ENTITIES.ODOMETER_READING] && { dimension13: appliedFilters[FILTER_ENTITIES.ODOMETER_READING] }),
        ...(appliedFilters[FILTER_ENTITIES.YEAR] && { dimension14: appliedFilters[FILTER_ENTITIES.YEAR] }),
        ...(appliedFilters[FILTER_ENTITIES.FUEL_TYPE] && { dimension15: appliedFilters[FILTER_ENTITIES.FUEL_TYPE] }),
        ...(appliedFilters[FILTER_ENTITIES.TRANSMISSION] && { dimension16: appliedFilters[FILTER_ENTITIES.TRANSMISSION] }),
        ...(appliedFilters[FILTER_ENTITIES.BODY_TYPE] && { dimension18: appliedFilters[FILTER_ENTITIES.BODY_TYPE]}),
        ...(selectedSort?.key && { dimension17: selectedSort?.key})
    };
};

const carData = (carDetailsData, {
    list,
    position,
    excludedKeys = [],
    filterDimensions = {}
}) => {
    const {
        appointmentId,
        carId,
        make,
        body,
        bodyType,
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        model,
        transmissionType,
        variant,
        egc,
        isPriceDrop
    } = carDetailsData || {};

    const {carLabel} = getCarInfo(carDetailsData);

    const data = {
        name: `${year} ${make} ${model}`,
        id: appointmentId || carId,
        price: egc,
        brand: make,
        category: body || bodyType,
        variant,
        list,
        price_drop: isPriceDrop ? "Yes" : "No",
        car_tag: carLabel,
        currency: "AUD",
        ...(position && {position}),
        dimension4: city,
        ...(views && {dimension5: views}),
        dimension6: odometerReading || kilometerDriven,
        dimension7: year,
        dimension8: transmissionType,
        dimension9: model,
        ...filterDimensions
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

const checkoutData = (carDetailsData, {
    bookingAmount,
    excludedKeys = []
}) => {
    const {
        appointmentId,
        carId,
        make,
        bodyType,
        body,
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        model,
        variant,
        transmissionType,
        egc
    } = carDetailsData || {};
    const data = {
        name: `${year} ${make} ${model}`,
        id: appointmentId || carId,
        price: egc,
        brand: make,
        category: bodyType || body,
        variant,
        dimension4: city,
        ...(views && {dimension5: views}),
        dimension6: odometerReading || kilometerDriven,
        dimension7: year,
        dimension8: transmissionType,
        dimension9: model,
        dimension19: bookingAmount
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

export default (payload, properties) => {
    return {
        carsImpression: {
            ecommerce: {
                currencyCode: CURRENCY.AU,
                impressions: [carData(payload, properties)]
            }
        },
        carDetails: {
            ecommerce: {
                detail: {
                    actionField: {
                        list: properties.list
                    },
                    products: [carData(payload, properties)]
                }
            }
        },
        carClick: {
            ecommerce: {
                click: {
                    actionField: { "list": properties.list },
                    products: [carData(payload, properties)]
                }
            }
        },
        startPurchase: {
            booking_id: properties.orderId,
            isOrderAvailable: !!properties.orderId,
            ecommerce: {
                currencyCode: CURRENCY.AU,
                add: {
                    products: [carData(payload, properties)]
                }
            }
        },
        checkout: {
            booking_id: properties.orderId,
            isOrderAvailable: !!properties.orderId,
            ecommerce: {
                checkout: {
                    actionField: { "step": properties.step, ...(properties.option && {option: properties.option})  },
                    products: [{
                        ...checkoutData(payload, properties)
                    }]
                }
            }
        },
        thankYou: {
            booking_id: properties.payload ? properties.payload.id : "",
            ecommerce: {
                purchase: {
                    actionField: { ...properties.payload, coupon: "", affiliation: "Online Store"},
                    products: [{
                        ...checkoutData(payload, properties)
                    }]
                }
            }
        },
        addtoWishList: [carData(payload, properties)]
    };
};
