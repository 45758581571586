export const generateDynamicStyles = (styles = {}, input = "") => {
    const output = [];

    if (input) {
        if (Array.isArray(input)) {
            input.forEach((value) => {
                output.push(styles[value]);
            });
        } else {
            output.push(styles[input]);
        }
    }

    return output.join(" ");
};
