export const TRADE_IN_BANNER_PROPERTY = {
    event: "custom_event",
    eventLabel: "trade_in_banner",
    eventCategory: "Cars24_detail_page"
};

export const TRADE_IN_GA_EVENTS = {
    tradeInPopupTestDriveViewed: {
        event: "custom_event",
        eventCategory: "Trade-in_popup_Test_drive",
        eventName: "Trade-in_Test_Drive",
        eventAction: "viewed",
        eventLabel: "order id"
    },
    tradeInPopupTestDriveClickedYesNo: {
        event: "custom_event",
        eventCategory: "Trade-in_popup_Test_drive",
        eventName: "Trade-in_Test_Drive",
        eventAction: "clicked",
        eventLabel: "Yes/No"
    },
    tradeInPopupTestDriveClickedSubmit: {
        event: "custom_event",
        eventCategory: "Trade-in_popup_Test_drive",
        eventName: "Trade-in_Test_Drive",
        eventAction: "clicked",
        eventLabel: "Submit"
    },
    tradeInSmallPopupViewed: {
        event: "custom_event",
        eventCategory: "Trade-in_small_popup",
        eventName: "Trade-in_Post_BC",
        eventAction: "viewed"
    },
    tradeInSmallPopupClosed: {
        event: "custom_event",
        eventCategory: "Trade-in_small_popup",
        eventName: "Trade-in_Post_BC",
        eventAction: "closed"
    },
    tradeInSmallPopupProceeded: {
        event: "custom_event",
        eventCategory: "Trade-in_small_popup",
        eventName: "Trade-in_Post_BC",
        eventAction: "proceeded"
    },
    tradeInBigPopupViewed: {
        event: "custom_event",
        eventCategory: "Trade-in_big_popup",
        eventName: "Trade-in_Post_BC",
        eventAction: "viewed"
    },
    tradeInBigPopupClosed: {
        event: "custom_event",
        eventCategory: "Trade-in_big_popup",
        eventName: "Trade-in_Post_BC",
        eventAction: "closed"
    },
    tradeInBigPopupProceeded: {
        event: "custom_event",
        eventCategory: "Trade-in_big_popup",
        eventName: "Trade-in_Post_BC",
        eventAction: "proceeded"
    },
    regoEntered: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Rego_entered"
    },
    stateEntered: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "State_entered"
    },
    yearEntered: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Year_entered"
    },
    odoEntered: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Odo_entered"
    },
    nextClicked: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Next_clicked"
    },
    variantSelected: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Variant_selected"
    },
    detailsSubmitted: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Details_submitted"
    },
    okayGotClicked: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Okay_got_clicked"
    },
    confirmationScreenClosed: {
        event: "custom_event",
        eventCategory: "Trade-in",
        eventName: "Trade-in_Post_BC",
        eventAction: "Trade-in_cofirmation_screen_closed"
    }
};
