import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VerifyDrivingLicence from "./component";
import { getLicenceUploadStatusByOrderId, saveDrivingLicenceDetails, setLicenceUploadScreenType } from "../checkout-info/actions";
import { withRouter } from "react-router-dom";

const mapStateToProps = ({
    checkout: {
        signContractData
    },
    carDetails: {
        content
    }
}) => ({
    signContractData,
    content
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getLicenceUploadStatusByOrderIdConnect: getLicenceUploadStatusByOrderId,
    saveDrivingLicenceDetailsConnect: saveDrivingLicenceDetails,
    setLicenceUploadScreenTypeConnect: setLicenceUploadScreenType
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyDrivingLicence));
