export default {
    CASHBACK_BANNER: "optimize.cashback_banner.activate",
    PRICE_REVISION: "optimize.price_revision.activate",
    DEAL_RATING: "optimize.deal_rating.activate",
    BOOK_NOW: "optimize.book_now.activate",
    EXCHANGE: "optimize.exchange.active",
    LOAN: "optimize.loan.activate",
    VIEW_360: "optimize.360_view.activate",
    HOMEPAGE_LEAD: "optimize.homepage_lead_capture.activate",
    OFFER_NUDGE: "optimize.offer_nudge.activate",
    THANK_YOU: "optimize.thank_you.activate",
    OFFER_GAME: "optimize.offer_game.activate",
    N1_PERSONALIZATION: "optimize.n1_personalization.activate",
    LISTING_COHORT: "optimize.listing_cohort.activate",
    CAR_SERVICE: "optimize.car_service.activate",
    LISTING_CARD: "optimize.listing_card.activate",
    CLASSIFIED_VS_INSTANT: "optimize.classified_vs_instant.activate",
    CAR_EVALUTATION_OTP_VERIFICATION: "optimize.car_evaluation_otp_verification.activate",

    CHECKOUT_FLOW: "optimize.checkout_flow.activate",
    DETAIL_REVAMP: "optimize.detail_revamp.activate",
    LISTING_FILTER: "optimize.listing_filter.activate",
    SELL_ONLINE: "optimize.sell_online.activate",
    REGISTRATION_NUMBER: "optimize.registration_number.activate",
    SHOW_EXPERIMENT_BANNER: "optimize.show_experiment_banner.activate",
    SHOW_FULL_SCREEN_GALLERY: "optimize.show_full_screen_gallery.activate",
    SHOW_DRIVA_CUSTOMIZE_REVAMP_SCREEN: "optimize.show_driva_customize_revamp_screen.activate",
    SHOW_PRE_APPORVAL_REVAMP_SCREEN: "optimize.show_pre_approval_revamp_screen.activate",
    SHOW_DELIVERY_TIMELINE: "optimize.show_delivery_timeline.activate",
    SHOW_PICK_UP_EXPERIMENT: "optimize.show_pick_up_experiment.activate",
    SHOW_TEST_DRIVE_SCREEN: "optimize.show_test_drive.activate",
    SHOW_IN_HOUSE_FINANCE: "optimize.show_in_house_finance.activate",
    SHOW_SAME_STATE_CARS: "optimize.show_same_state_cars.activate",
    CAR_COMPARE_EXPERIMENT: "optimize.show_in_house_finance.activate.show_car_compare",
    SHOW_INSPECTION_REPORT: "optimize.show_inspection_report.activate",
    SHOW_CHAT_WIDGET: "optimize.show_chat_widget.activate",
    SHOW_WARRANTY_COMPLIMENTARY: "optimize.show_warranty_complimentary.activate",
    SHOW_PLENTI_EXPERIMENT_VIEW: "optimize.show_plenti_experiment_view.activate",
    SHOW_NO_IMPERFECTION: "optimize.show_no_imperfection.activate",
    SHOW_PAGE_WARRANTY_SHIFT: "optimize.show_page_warranty_shift.activate",
    SHOW_NON_PREAPPROVED_PAY_OPTIONS: "optimize.show_non_preapproved_pay_options.activate",
    SHOW_MINIMUM_SPLIT_PAYMENT: "optimize.show_minimum_split_payment.activate",
    SHOW_PREMIUM_FEATURES: "optimize.show_premium_features.activate",
    SHOW_ZERO_DOWNPAYMENT: "optimize.show_zero_downpayment.activate",
    SHOW_LENDER_DISPLAY_EXPERIMENT: "optimize.show_lender_display_experiment",
    SHOW_HOME_PAGE_REVAMP: "optimize.show_home_page_revamp.activate",
    SHOW_CHAT_EMAIl_MODAL: "optimize.show_chat_email_modal.activate",
    SHOW_SELL_CAR_BANNER: "optimize.show_sell_car_banner.activate",
    SHOW_AUTO_SUGGESTION_REVAMP: "optimize.show_auto_suggestion_revamp.activate",

    SHOW_CDP_GALLERY_VARIANT: "optimize.show_cdp_gallery_variant.activate"
};

export const EXPERIMENT_IDENTIFIER = {
    EXCHANGE: "ab_exchange_flow",
    LOAN: "ab_loan_flow",
    VIEW_360: "view_360",
    OFFER_NUDGE: "offer_nudge",
    THANK_YOU: "thank_you",
    OFFER_GAME: "offer_game",
    N1_PERSONALIZATION: "n1_personalization",
    LISTING_COHORT: "listing_cohort",
    CAR_SERVICE: "car_service",
    LISTING_CARD: "listing_card",
    CLASSIFIED_VS_INSTANT: "classified_vs_instant",
    CAR_EVALUTATION_OTP_VERIFICATION: "car_evaluation_otp_verification",
    CHECKOUT_FLOW: "checkout_flow",
    DETAIL_REVAMP: "detail_revamp",
    LISTING_FILTER: "listing_filter",
    SELL_ONLINE: "sell_online",
    REGISTRATION_NUMBER: "registration_number",
    SHOW_EXPERIMENT_BANNER: "show_experiment_banner",
    SHOW_FULL_SCREEN_GALLERY: "show_full_screen_gallery",
    SHOW_DRIVA_CUSTOMIZE_REVAMP_SCREEN: "show_driva_customize_revamp_screen",
    SHOW_PRE_APPORVAL_REVAMP_SCREEN: "show_pre-approval_revamp_screen",
    SHOW_DELIVERY_TIMELINE: "show_delivery_timeline",
    SHOW_PICK_UP_EXPERIMENT: "show_pick_up_experiment",
    SHOW_TEST_DRIVE_SCREEN: "show_test_drive",
    SHOW_IN_HOUSE_FINANCE: "show_in_house_finance",
    SHOW_SAME_STATE_CARS: "show_same_state_cars",
    CAR_COMPARE_EXPERIMENT: "show_car_compare",
    SHOW_INSPECTION_REPORT: "show_inspection_report",
    SHOW_CHAT_WIDGET: "show_chat_widget",
    SHOW_WARRANTY_COMPLIMENTARY: "show_warranty_complimentary",
    SHOW_PLENTI_EXPERIMENT_VIEW: "show_plenti_experiment_view",
    SHOW_NO_IMPERFECTION: "show_no_imperfection",
    SHOW_PAGE_WARRANTY_SHIFT: "show_page_warranty_shift",
    SHOW_NON_PREAPPROVED_PAY_OPTIONS: "show_non_preapproved_pay_options",
    SHOW_MINIMUM_SPLIT_PAYMENT: "show_minimum_split_payment",
    SHOW_PREMIUM_FEATURES: "show_premium_features",
    SHOW_ZERO_DOWNPAYMENT: "show_zero_downpayment",
    SHOW_LENDER_DISPLAY_EXPERIMENT: "show_lender_display_experiment",
    SHOW_HOME_PAGE_REVAMP: "show_home_page_revamp",
    SHOW_CHAT_EMAIl_MODAL: "show_chat_email_modal",
    SHOW_SELL_CAR_BANNER: "show_sell_car_banner",
    SHOW_AUTO_SUGGESTION_REVAMP: "show_auto_suggestion_revamp",
    SHOW_CDP_GALLERY_VARIANT: "show_cdp_gallery_variant"

};

export const EXPERIMENT_TYPE = {
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B",
    VARIANT_C: "VARIANT_C",
    CONTROL: "CONTROL"
};

export const ZERO_DP_VARIANTS = {
    ZERO_DP: "ZERO_DP",
    NON_ZERO_DP: "NON_ZERO_DP"
};

export const EVENT_LABELS = {
    [EXPERIMENT_TYPE.VARIANT_A]: "Variant_A",
    [EXPERIMENT_TYPE.VARIANT_B]: "Variant_B",
    [EXPERIMENT_TYPE.VARIANT_C]: "Variant_C"
};

export const CASED_EXPERMIMENT_TYPE = {
    VARIANT_A: "variant_a",
    VARIANT_B: "variant_b",
    VARIANT_C: "variant_c"
};
// todo:deepak get from backend
export const CURRENT_SALE = "SALE";

export const CONFIG_BODY = {
    NUDGE_CONFIG: {
        configType: "NUDGE_CONFIG"
    },
    SALE: {
        configType: "SALE"
    },
    CHECKOUT_SCREEN_CONFIG: {
        configType: "CHECKOUT_SCREEN_CONFIG"
    }
};
