import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
const StepsComponentRevamp = ({title, steps = []}) => {
    return (
        <React.Fragment>
            {title && <h2 styleName={"styles.secondSectionHeader"}>{title}</h2>}
            <div styleName={"styles.interestRateWrapper"}>
                {steps?.map((step, index) => (
                    <p styleName={"styles.stepsWrap"} key={index}><span>{index + 1}</span>{step}</p>
                ))}
            </div>
        </React.Fragment>
    );
};

StepsComponentRevamp.propTypes = {
    title: PropTypes.string,
    steps: PropTypes.array
};

export default StepsComponentRevamp;
